import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { db, auth } from "../firebase_setup/firebase"
import { getDoc, doc } from "firebase/firestore"
import DonutChart from "../components/DonutChart"
import maturity_model from "../data/maturity_model.json"
import styles from "./Results.module.css"

export default function Results() {
  const { site } = useParams()
  const [overall, setOverall] = useState(0)
  const [means, setMeans] = useState({})

  useEffect(() => {
    async function getMeans() {
      const responses = (await getDoc(doc(db, "users", auth.currentUser.uid, "responses", site))).data();
      let totalSum = 0, numQuestions = 0
      for (let dim in responses) {
        let scores = Object.values(responses[dim])
        let sum = scores.reduce((a, b) => a + b, 0)
        setMeans(prevMeans => {
          return {...prevMeans, [dim]: Math.round(sum / scores.length*10)/10}
        })

        totalSum += sum
        numQuestions += scores.length
      }
      setOverall(Math.round(totalSum / numQuestions * 100)/100)
    }

    getMeans().catch(console.error)
  }, [site])

  //FIXME: TEST DATA

  // const overall = 4.1
  // const means = {
  //   "Strategy, Structure and Organizational Culture": 0.4,
  //   "Workforce": 1.3,
  //   "Smart Factories": 2.2,
  //   "Smart Processes": 3.1,
  //   "Smart Products and Services": 4.4,
  //   // "Test": 4.9
  // }

  // hardcoded pain points/possible solutions generated by a large language model
  // potential to incorporate an open-source API? or hard-code solutions based off actual model
  const painPoints =[
    "Does the company share relevant information with other companies of the value chain in order to have an agile decision making process?",
    "Does the company have the required technical and managerial abilities to implement the transformational actions of Industry 4.0?",
    "Does the company encourage the creativity and empowerment of employees considering the challenges and benefits of digital transformation?",
    "Does the company have information, communication and operation systems integrated and capable of meeting interoperability requirements?",
    "Does the company collect data from sensors and actuators, without human intervention and in real time?"
  ]
  const painAnswers = [
    "Share relevant information with partners in the value chain by implementing a data-sharing platform or system to improve communication and decision-making processes.",
    "Conduct a skills assessment, provide training, and hire experts or consultants to support transformational actions and guide technical and managerial aspects.",
    "Create an innovation culture by providing employee training programs, establishing cross-functional teams, and incentivizing innovative ideas and successful digital transformation projects.",
    "Implement an integrated system that combines information, communication, and operation systems to improve interoperability and streamline processes. Invest in standardized technology solutions to enable easy integration and data sharing.",
    "Implement an IoT system that collects data from sensors and actuators in real-time. Connect it to a cloud-based platform to store and analyze data, and invest in predictive maintenance solutions to detect potential issues and prevent downtime."
  ]

  return (
    <div className={styles.results}>
      <div className={styles.title}>
        <h1>MATURITY ASSESSMENT RESULTS</h1>
        <span>{site}</span>
      </div>
      <div className={styles.sections}>
        <div>
          <div className={styles.overall}>
            <h3>Overall Maturity Index</h3>
            <span>{overall}</span>
          </div>
          <div className={styles.pains}>
            <h3>Solutions for Key Pain Points</h3>
            <ol className={styles.list}>
              {painPoints.map((q,i) => { return (
                <li key={i}>
                  <b>{q}</b>
                  <br/>
                  {painAnswers[i]}
                  <br/><br/>
                </li>
              )})}
            </ol>
          </div>
        </div>
        <DonutChart
          overall={overall}
          labelledMeans={means}
          levels={maturity_model.levels.length}
        />
      </div>
    </div>
  )
}