import React, { createContext, useContext, useState } from 'react';
// Import the type or interface from the MatFlow file if needed

// Interface for component props
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for supplier material
const AllSupMatContext = createContext({
  SupMats: [],
  updateSupMat: () => {},
});

// Custom hook to use the supplier material context
export const useAllSupMatContext = () => {
  const context = useContext(AllSupMatContext);
  if (!context) {
    throw new Error('useAllSupMatContext must be used within a SupMatProvider');
  }
  return context;
};

// Provider for the supplier material context
export const AllSupMatProvider = ({ children }) => {
  const [SupMats, setSupMats] = useState([]);

  // Function to update a supplier material by index
  const updateSupMat = (index, newSupMat) => {
    console.log("here11111")
    console.log(index)

    console.log(newSupMat)

    if (index === 0) {
      setSupMats([newSupMat]);
    } else {
      setSupMats((prevSupMats) => {
        // Create a new array with the updated supplier material at the specified index
        const updatedSupMats = [...prevSupMats];
        updatedSupMats[index] = newSupMat;
        return updatedSupMats;
      });
    }
    console.log("SupMats: " + SupMats)
    console.log(SupMats)
  };

  // Value provided to consumers of the supplier material context
  const contextValue = {
    SupMats,
    updateSupMat,
  };

  return (
    <AllSupMatContext.Provider value={contextValue}>
      {children}
    </AllSupMatContext.Provider>
  );
};
