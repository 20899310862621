import { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import styles from "./Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { BarChart } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase_setup/firebase";

const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default function Settings5S() {
  const [company, setCompany] = useState({});
  const [supervisors, setSupervisors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Extract audits, auditAreas, and supervisors from the data
          const { auditAreas, supervisors, ...otherData } = data;
          
          setCompany(otherData); // Set the rest of the data as company
          setSupervisors(supervisors); // Set supervisors, default to empty array if null
          setAreas(auditAreas); // Set auditAreas, default to empty array if null
          
          // Here, audits, auditAreas, and supervisors will be arrays if they exist
          console.log("Audit Areas:", auditAreas);
          console.log("Supervisors:", supervisors);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.header}>
        <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>
        <Box>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "50px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settingsUser.map((item) => (
              <MenuItem
                component={NavLink}
                to={item.path}
                key={item.title}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
      <Grid
        container
        sx={{
          padding: "5rem 0rem 2rem",
          fontSize: "1.1rem",
          marginLeft: "10%",
          width: "80%"
        }}
      >
        <Grid item xs={8}>
          <Typography
            component="h3"
            sx={{
              fontSize: "2.5rem",
              fontWeight: 600,
            }}
          >
            Company's Name:
          </Typography>
          <Typography>{company.company}</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: "2.5rem",
              fontWeight: 600,
            }}
          >
            Audits Area:
          </Typography>
          <Typography sx={{ marginBottom: "0.5rem" }}>
            {areas.map((area, index) => (
              <Typography key={index}>{area}</Typography>
            ))}
          </Typography>
          <Button variant="contained" component={NavLink} to="/areaSetting5S">Edit</Button>
        </Grid>

        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: "2.5rem",
              fontWeight: 600,
            }}
          >
            Supervisors:
          </Typography>
          <Typography sx={{ marginBottom: "0.5rem" }}>
            {supervisors.map((supervisor, index) => (
              <Typography key={index}>{supervisor}</Typography>
            ))}
          </Typography>
          <Button variant="contained" component={NavLink} to="/supervisorSetting5S">Edit</Button>
        </Grid>
      </Grid>
    </>
  );
}
