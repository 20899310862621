import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";
import '../styles/tabsCSS.css';

import { useFieldArray,  } from 'react-hook-form'
import { useSupplierContext } from '../contexts/SupplierContext';
import { useEffect } from 'react';
import { useAllSupplierContext } from '../contexts/SupHandlerContext';
import { useCustomerContext } from '../contexts/CustomerContext';

import { useProcessContext } from '../contexts/ProcessContext';
import { useAllInventoryContext } from '../contexts/InventoryContext';

import { useCustomerMaterialFlowContext } from '../contexts/CustomerMatContext'
import { useAllSupMatContext } from '../contexts/SupMatContext';
import { useAllCusProdContext } from '../contexts/CusProdContext';
import { useAllProcProdContext } from '../contexts/ProProdContext';
import { useAllSupProdContext } from '../contexts/SupProdContext';


const CustomerProductionForm = ({ index, register, errors, lastVisited, setLastVisited }) => {
    useEffect(() => {
      setLastVisited((prevState) => {
        const newState = [...prevState];
        newState[index] = 1;
        console.log(`Value in position ${index}:`, newState[index]);
        return newState;
      });
    }, []);
  
    return (
      <div className={`customer&MRP.${index}`}>
        <br />
        <h3>Flow Between Customer and Production Control</h3>
        <br />
        <label htmlFor={`typeCus.${index}`}>Type of Information:</label>
        <select {...register(`customerProd.${index}.typeCus`, {
          required: {
            value: true,
            message: "Type of Information is Required"
          }
        })}>
          <option value="" disabled hidden>Select an option</option>
          <option value="eletronic">Eletronic (Email, Message ...)</option>
          <option value="physical">Physical (Document, Told ...)</option>
        </select>
        <p className='errorsValidation'>{errors?.customerProd?.[index]?.typeCus?.message}</p>
        <br /><br />
        <label htmlFor={`receiveCus.${index}`}>Who receive the Information:</label>
        <select {...register(`customerProd.${index}.receiveCus`, {
          required: {
            value: true,
            message: "Receiver is Required!"
          }
        })}>
          <option value="" disabled hidden>Select an option</option>
          <option value="Production Control">Production Control</option>
          <option value="Customer">Customer</option>
        </select>
        <p className='errorsValidation'>{errors?.customerProd?.[index]?.receiveCus?.message}</p>
        <br /><br />
        <label htmlFor={`periodCus.${index}`}>Period (Specify the Unit):</label>
        <input type="text" {...register(`customerProd.${index}.periodCus`)} />
        <br />
        <label htmlFor={`contentCus.${index}`}>Information Content:</label>
        <input type="text" {...register(`customerProd.${index}.contentCus`)} />
        <br />
      </div>
    );
  };
  
  const SupplierProductionForm = ({ index, register, errors, numberOfSuppliers, lastVisited, setLastVisited }) => {
    useEffect(() => {
      setLastVisited((prevState) => {
        const newState = [...prevState];
        newState[index] = 2;
        console.log(`Valor na posição ${index}:`, newState[index]);
        return newState;
      });
    }, []);
  
    return (
      <div>
        <div className={`supplier&production${index}`}>
          <br />
          <h3>Flow Between Supplier and Production Control</h3>
          <br />
          <label htmlFor={`typeSup${index}`}>Type of Information:</label>
          <select {...register(`supplierProd.${index}.typeSup`, {
            required: {
              value: true,
              message: "Type of Information is Required!"
            }
          })}>
            <option value="" disabled hidden>Select an option</option>
            <option value="eletronic">Eletronic (Email, SMS, ...)</option>
            <option value="physical"> Physical (Document, Told, ...)</option>
          </select>
          <p className='errorsValidation'>{errors?.supplierProd?.[index]?.typeSup?.message}</p>
          <br /><br />
          <label htmlFor={`receiveSup.${index}`}>Who receive the Information:</label>
          <select {...register(`supplierProd.${index}.receiveSup`, {
            required: {
              value: true,
              message: "Receiver is Required!"
            }
          })}>
            <option value="" disabled hidden>Select an option</option>
            <option value="Production Control">Production Control</option>
            <option value="Supplier">Supplier</option>
          </select>
          <p className='errorsValidation'>{errors?.supplierProd?.[index]?.receiveSup?.message}</p>
          <br /><br />
          <label htmlFor={`periodSup.${index}`}>Period (Specify the Unit):</label>
          <input type="text" {...register(`supplierProd.${index}.periodSup`)} />
          <br />
          <label htmlFor="contentSup">Information Content:</label>
          <input type="text" {...register(`supplierProd.${index}.contentSup`)} />
          <br />
          <label htmlFor="supNumber">Supplier Number (Same in Supplier Tab):</label>
          <input type="number"
            {...register(`supplierProd.${index}.supNumber`, {
              required: {
                value: true,
                message: "Supplier Number is Required"
              },
              validate: (value) => {
                if (value != null) {
                  if (value > numberOfSuppliers) {
                    return `You just added ${numberOfSuppliers} Suppliers!`
                  }
                  if (value < 1) {
                    return `Choose a Valid Number Between 1 and ${numberOfSuppliers}`
                  }
                }
              }
            })} />
          <p className='errorsValidation'>{errors?.supplierProd?.[index]?.supNumber?.message}</p>
          <br /><br />
        </div>
      </div>
    );
  };

const ProcessProductionForm = ({ index, register, errors, numberOfProcess, lastVisited, setLastVisited }) => {
    useEffect(() => {
      setLastVisited((prevState) => {
        const newState = [...prevState];
        newState[index] = 3;
        console.log(`Valor na posição ${index}:`, newState[index]);
        return newState;
      });
    }, []);
  
    return (
      <div className={`process&MRP.${index}`}>
        <br />
        <h3>Flow Between Process and Production Control</h3>
        <br />
        <label htmlFor={`typeProcess.${index}`}>Type of Information:</label>
        <select {...register(`processProd.${index}.typeProcess`, {
          required: {
            value: true,
            message: "Type of Information is Required!"
          }
        })}>
          <option value="" disabled hidden>Select an option</option>
          <option value="eletronic">Eletronic (Email, Message ...)</option>
          <option value="physical">Physical (Document, Told ...)</option>
        </select>
        <p className='errorsValidation'>{errors?.processProd?.[index]?.typeProcess?.message}</p>
        <br /><br />
        <label htmlFor={`receiveProcess.${index}`}>Who receive the Information:</label>
        <select {...register(`processProd.${index}.receiveProcess`, {
          required: {
            value: true,
            message: "Receiver is Required!"
          }
        })}>
          <option value="" disabled hidden>Select an option</option>
          <option value="Production Control">Production Control</option>
          <option value="Process">Process</option>
        </select>
        <p className='errorsValidation'>{errors?.processProd?.[index]?.receiveProcess?.message}</p>
        <br /><br />
        <label htmlFor={`processProd.${index}.periodProcess`}>Period (Specify the Unit):</label>
        <input type="text"
          {...register(`processProd.${index}.periodProcess`)} />
        <br />
        <label htmlFor={`contentProcess.${index}`}>Information Content:</label>
        <input type="text"
          {...register(`processProd.${index}.contentProcess`)} />
        <br /><br />
        <label htmlFor={`processNumber.${index}`}>Process Number:</label>
        <input type="number"
          {...register(`processProd.${index}.processNumber`, {
            require: {
              value: true,
              message: "Process Number is Required"
            },
            validate: (value) => {
              if (value != null) {
                if (value > numberOfProcess) {
                  return `You Have Just Added ${numberOfProcess} Process!`
                }
                if (value < 1) {
                  return `Choose a Valid Number Between 1 and ${numberOfProcess}`
                }
              }
            }
          })} />
        <p className='errorsValidation'>{errors?.processProd?.[index]?.processNumber?.message}</p>
      </div>
    );
  };

export default function VSMInfoFlow() {
    const handleOptionChange = (index, value) => {
        const newSelectedOptions = [...selectedOptions]
        newSelectedOptions[index] = value;
        setSelectedOptions(newSelectedOptions)
    };
    
    const [numConections, setNumConections] = useState (1);
    const { numberOfProcess } = useProcessContext();
    const {numberOfSuppliers} = useSupplierContext();
    const{CusProds, updateCusProd} = useAllCusProdContext();
    const {SupProds, updateSupProd} = useAllSupProdContext();
    const {ProcProds, updateProcProd} = useAllProcProdContext();
    const navigate = useNavigate();
    const [lastVisited, setLastVisited] = useState([]); //CHANGED
    
    const {register,control, handleSubmit, formState} = useForm({
        defaultValues:{
            selectbox:[{connection:"Select an Option"}],
            supplierProd:[{typeSup: undefined, receiveSup: "Select an Option", periodSup: null, contentSup: null, supNumber: null}],
            processProd:[{typeProcess: undefined, receiveProcess: "Select an Option", periodProcess: null, contentProcess: null, processNumber: null}],
            customerProd:[{typeCus: undefined, receiveCus: "Select an Option", periodCus: null, contentCus: null}]
        }
    });


    const {errors} = formState;
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'selectbox',
    });
    const [selectedOptions, setSelectedOptions] = useState(fields.map(() => ""));

    const onSubmit = async (data) =>{
        try {
            let numSup = 0;
            let numCus = 0;
            let numProc = 0;

            for(let i = 0 ; i < numConections ; i++){
                console.log("Round", i)
                console.log(lastVisited)

                
                if(lastVisited[i] === 1){
                    console.log("entra em customer")
                    const updatedCusProd = {
                        typeCus: data.customerProd[i].typeCus,
                        receiveCus: data.customerProd[i].receiveCus,
                        periodCus: data.customerProd[i].periodCus,
                        contentCus: data.customerProd[i].contentCus
                    };
                    updateCusProd(numCus, updatedCusProd);
                    console.log("Customer", data.customerProd[i], "Adicionado em ", numCus);
                    numCus++;
                    console.log("novo numCus: ",numCus);   
                }

                else if(lastVisited[i] === 2){
                    console.log("entra em supplier")
                    const updatedSupProd = {
                        typeSup: data.supplierProd[i].typeSup,
                        receiveSup: data.supplierProd[i].receiveSup,
                        periodSup: data.supplierProd[i].periodSup,
                        contentSup: data.supplierProd[i].contentSup,
                        supNumber: data.supplierProd[i].supNumber
                    };
                    updateSupProd(numSup, updatedSupProd);
                    console.log("Suuplier", data.supplierProd[i], "Adicionado em ", numSup);
                    numSup++;
                    console.log("novo numSup: ",numSup);   
                }
            
                else if(lastVisited[i] === 3){
                    console.log("entra em proc")
                    const updatedProcProd = {
                        typeProcess: data.processProd[i].typeProcess,
                        receiveProcess: data.processProd[i].receiveProcess,
                        periodProcess: data.processProd[i].periodProcess,
                        contentProcess: data.processProd[i].contentProcess,
                        processNumber: data.processProd[i].processNumber
                    };
                    updateProcProd(numProc, updatedProcProd);
                    console.log("Processo", data.processProd[i], "Adicionado em ", numProc);
                    numProc++;
                    console.log("novo numProc: ",numProc);   
                }
                else{
                    console.log("connection ", i, "failed")
                }
            }
            navigate('/reviewFormVSM')
            
        } catch (error) {
            console.log("Error submitting", error)
        }
    }
    
    
    const renderSelectedForm = (index) => {
        switch (selectedOptions[index]) {
          case "customer&MRP-1":
            return <CustomerProductionForm index={index} register={register} errors = {errors} lastVisited = {lastVisited} setLastVisited = {setLastVisited}/>;
          case "supplier&MRP-1":
            return <SupplierProductionForm index={index} register={register} errors = {errors} numberOfSuppliers = {numberOfSuppliers} lastVisited = {lastVisited} setLastVisited = {setLastVisited} />;
          case "process&MRP-1":
            return <ProcessProductionForm index={index} register={register} errors = {errors} numberOfProcess = {numberOfProcess} lastVisited = {lastVisited} setLastVisited = {setLastVisited}/>;
        }
      };

    const handleAdd = () =>{
        append ({ connection: '' });
        setNumConections(prevNumConnections => prevNumConnections + 1);
        console.log(numConections)
    }

    const handleRemoveAndDecrement = (index) => {
        // Remove o processo usando o índice fornecido
        remove(index);
        
        // Decrementa o índice
        setNumConections(prevNumConnections => prevNumConnections - 1);
        console.log("index é ", index)
    };

    const handlePrevious = () =>{
        navigate('/matflowVSM')
    }

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Supplier</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Customer</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Process Creation</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Inventory</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Material Flow Data</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>

            <div className='tab'>
            <form id="inventoryForm" autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data))} noValidate>
            <div className="buttons-div">
                        <div className='previousButton'>
                            <button type="button" onClick={handlePrevious}>Previous</button>
                        </div>
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
                <br/><br/>
            <h2>Informational Flow</h2>
                
                {fields.map((field, index) =>(
                    <div key= {field.id}>
                        <label htmlFor="infoType">Connection {index+1}</label>
                        <select  className="options-in-menu-1" value={selectedOptions[index]} onChange={(e) => handleOptionChange(index, e.target.value)}>
                            <option value="" disabled hidden selected> Select an option </option>
                            <option value="customer&MRP-1">Between Customer and Production Control</option>
                            <option value="supplier&MRP-1">Between Supplier and Production Control</option>
                            <option value="process&MRP-1">Between Process and Production Control</option>
                        </select>
                        {selectedOptions[index] && renderSelectedForm(index)}
                        <br/><br/>
                        <button type="button" id="removeProcessButton" className="removeProcessButton" onClick={() => handleRemoveAndDecrement(index)}>Remove Process</button>
                        <br/><br/>
                    </div>
                ))}
                <button type="button" id="addProcessButton" className="addProcessButton" onClick={() => handleAdd()}>Add Item</button>
            </form>
            </div>
    </div>
  );
}

