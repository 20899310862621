import { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import styles from "./Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Grid, Button, Card, CardContent } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink, useLocation } from "react-router-dom";
import { BarChart, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase_setup/firebase";

const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default function SettingsEdit() {
  const location = useLocation();
  const [areas, setAreas] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [name, setName] = useState('');
  const [dataList, setDataList] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const { auditAreas = [], supervisors = [] } = data;

          setSupervisors(supervisors);
          setAreas(auditAreas);

          const urlPath = location.pathname.split("/")[1];
          if (urlPath === "areaSetting5S") {
            setName("area");
            setDataList(auditAreas);
          } else {
            setName("supervisor");
            setDataList(supervisors);
          }

          console.log("Audit Areas:", auditAreas);
          console.log("Supervisors:", supervisors);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };

    fetchData();
  }, [location.pathname]);

  const handleDelete = async (index) => {
    try {
      const companyName = sessionStorage.getItem('companyChosen');
      const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        let updatedArray;

        if (name === "area") {
          updatedArray = data.auditAreas.filter((_, i) => i !== index);
          await updateDoc(docRef, { auditAreas: updatedArray });
          setAreas(updatedArray);
          setDataList(updatedArray);
        } else if (name === "supervisor") {
          updatedArray = data.supervisors.filter((_, i) => i !== index);
          await updateDoc(docRef, { supervisors: updatedArray });
          setSupervisors(updatedArray);
          setDataList(updatedArray);
        }

        console.log(`${name} at index ${index} has been deleted.`);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
          <div className={styles.header}>
        <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>
        <Box>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "50px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settingsUser.map((item) => (
              <MenuItem
                component={NavLink}
                to={item.path}
                key={item.title}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
      <h1 style={{marginTop: "1rem", marginLeft: "2rem"}}>Settings Edit {name.charAt(0).toUpperCase() + name.slice(1)}</h1>
      {dataList.length > 0 ? (
        dataList.map((data, index) => (
          <Card
            key={index}
            sx={{ margin: " 1rem 2rem 1rem", backgroundColor: "aliceblue" }}
          >
            <CardContent sx={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>{data}</div>

              <Button
                variant="contained"
                size="small"
                sx={{ marginLeft: "0.3rem" }}
                component={NavLink}
                to={`/${name}Setting5S/${index}/edit`}
              >
                <EditIcon fontSize="small" />
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ marginLeft: "0.3rem" }}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon fontSize="small" color="error" />
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>No {name}s found</Typography>
      )}
      <Button
        variant="contained"
        component={NavLink}
        to={`/${name}Setting5S/add`}
        sx={{ marginLeft: "2rem" }}
      >
        Add
      </Button>
    </>
  );
}
