import React, { createContext, useContext, useState } from 'react';
// Import the type or interface from the infoFlow file if needed

// Interface for component props
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for supplier products
const AllSupProdContext = createContext({
  SupProds: [],
  updateSupProd: () => {},
});

// Custom hook to use the supplier products context
export const useAllSupProdContext = () => {
  const context = useContext(AllSupProdContext);
  if (!context) {
    throw new Error('useAllSupProdContext must be used within a SupProdProvider');
  }
  return context;
};

// Provider for the supplier products context
export const AllSupProdProvider = ({ children }) => {
  const [SupProds, setSupProds] = useState([]);

  // Function to update a supplier product by index
  const updateSupProd = (index, newSupProd) => {
    if (index === 0) {
      setSupProds([newSupProd]);
    } else {
      setSupProds((prevSupProds) => {
        // Create a new array with the updated supplier products at the specified index
        const updatedSupProds = [...prevSupProds];
        updatedSupProds[index] = newSupProd;
        return updatedSupProds;
      });
    }
  };

  // Value provided to consumers of the supplier products context
  const contextValue = {
    SupProds,
    updateSupProd,
  };

  return (
    <AllSupProdContext.Provider value={contextValue}>
      {children}
    </AllSupProdContext.Provider>
  );
};
