
import { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import styles from "./Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { BarChart } from "@mui/icons-material";
import { Grid, Select } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { BarChartCustom } from "../components/statistics/BarChart";
import { RadarChart } from "../components/statistics/RadarChart";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { auth, db } from "../firebase_setup/firebase";


const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default function Statistics() {
  const [audits, setAudits] = useState([]);
  const [auditsGotten, setAuditsGotten] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [company, setCompany] = useState({});
  const [supervisors, setSupervisors] = useState([]);
  const [areas, setAreas] = useState([]);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const data = docSnap.data();
          const { auditAreas, supervisors, ...otherData } = data;
  
          setCompany(otherData);
          setSupervisors(supervisors || []);
          setAreas(auditAreas || []);
  
          // Fetch audits sub-collection
          const auditsCollectionRef = collection(docRef, "audits");
          const auditsSnapshot = await getDocs(auditsCollectionRef);

          const auditsGotten = auditsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setAuditsGotten(auditsGotten);

          const audits = await Promise.all(
            auditsSnapshot.docs.map(async (auditDoc) => {
              const auditData = auditDoc.data();
              const [
                sortAnswerDoc,
                setInOrderAnswerDoc,
                shineAnswerDoc,
                standardizeAnswerDoc,
                sustainAnswerDoc
              ] = await Promise.all([
                getDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "sortAnswers", auditData.sort_id)),
                getDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "setAnswers", auditData.setinorder_id)),
                getDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "shineAnswers", auditData.shine_id)),
                getDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "standardizeAnswers", auditData.standardize_id)),
                getDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "sustainAnswers", auditData.sustain_id))
              ]);
  
              return {
                ...auditData,
                SortAnswer: sortAnswerDoc.exists() ? sortAnswerDoc.data() : {},
                SetInOrderAnswer: setInOrderAnswerDoc.exists() ? setInOrderAnswerDoc.data() : {},
                ShineAnswer: shineAnswerDoc.exists() ? shineAnswerDoc.data() : {},
                StandardizeAnswer: standardizeAnswerDoc.exists() ? standardizeAnswerDoc.data() : {},
                SustainAnswer: sustainAnswerDoc.exists() ? sustainAnswerDoc.data() : {}
              };
            })
          );
  
          setAudits(audits);
          console.log("Audit Areas:", auditAreas);
          console.log("Audit Gotten:", audits);
          console.log("Supervisors:", supervisors);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };
  
    fetchData();
  }, []); 

  // Filter audits by selected area
  const filteredAudits = selectedArea
  ? audits.filter((audit) => audit.area_id === selectedArea)
  : audits;

  // Processes audit data for the bar chart.
  // Calculate the total score for each audit by adding the responses from each section.
  const dataBarChart = audits.map((audit, index) => {
    // Calculate score_sort
    const score_sort =
      audit.SortAnswer.answer1 +
      audit.SortAnswer.answer2 +
      audit.SortAnswer.answer3 +
      audit.SortAnswer.answer4 +
      audit.SortAnswer.answer5;

    const score_set = 
    audit.SetInOrderAnswer.answer6 +
    audit.SetInOrderAnswer.answer7 +
    audit.SetInOrderAnswer.answer8 +
    audit.SetInOrderAnswer.answer9 +
    audit.SetInOrderAnswer.answer10;

    return {
      id: index + 1,
      date: dayjs(audit.date).format("YYYY-MM-DD"),
      score_sort: score_sort,
      score_set: score_set,
      score_shine:
        audit.ShineAnswer.answer11 +
        audit.ShineAnswer.answer12 +
        audit.ShineAnswer.answer13 +
        audit.ShineAnswer.answer14 +
        audit.ShineAnswer.answer15,
      score_standardize:
        audit.StandardizeAnswer.answer16 +
        audit.StandardizeAnswer.answer17 +
        audit.StandardizeAnswer.answer18 +
        audit.StandardizeAnswer.answer19 +
        audit.StandardizeAnswer.answer20,
      score_sustain:
        audit.SustainAnswer.answer21 +
        audit.SustainAnswer.answer22 +
        audit.SustainAnswer.answer23 +
        audit.SustainAnswer.answer24 +
        audit.SustainAnswer.answer25,
    };
  });
  
  // Calculating area-specific data for charts
  const dataBarChartFilter = filteredAudits.map((audit, index) => ({
    id: index + 1,
    date: dayjs(audit.date).format("YYYY-MM-DD"),
    score_sort:
      audit.SortAnswer.answer1 +
      audit.SortAnswer.answer2 +
      audit.SortAnswer.answer3 +
      audit.SortAnswer.answer4 +
      audit.SortAnswer.answer5,
    score_set:
      audit.SetInOrderAnswer.answer6 +
      audit.SetInOrderAnswer.answer7 +
      audit.SetInOrderAnswer.answer8 +
      audit.SetInOrderAnswer.answer9 +
      audit.SetInOrderAnswer.answer10,
    score_shine:
      audit.ShineAnswer.answer11 +
      audit.ShineAnswer.answer12 +
      audit.ShineAnswer.answer13 +
      audit.ShineAnswer.answer14 +
      audit.ShineAnswer.answer15,
    score_standardize:
      audit.StandardizeAnswer.answer16 +
      audit.StandardizeAnswer.answer17 +
      audit.StandardizeAnswer.answer18 +
      audit.StandardizeAnswer.answer19 +
      audit.StandardizeAnswer.answer20,
    score_sustain:
      audit.SustainAnswer.answer21 +
      audit.SustainAnswer.answer22 +
      audit.SustainAnswer.answer23 +
      audit.SustainAnswer.answer24 +
      audit.SustainAnswer.answer25,
  }));
  

  // Converts the audit data into a format suitable for the DataGrid table.
  // The dayjs function is used to format the date.
  const rows = audits.map((audit, index) => {
    // Calculate the score
    const score =
      dataBarChart[index].score_sort +
      dataBarChart[index].score_set +
      dataBarChart[index].score_shine +
      dataBarChart[index].score_standardize +
      dataBarChart[index].score_sustain;

    return {
      id: index + 1,
      date: dayjs(audit.date).format("YYYY-MM-DD"),
      area: audit.area_id ? audit.area_id : "No Area Assigned",
      supervisor: audit.supervisor_id ? audit.supervisor_id : "No Supervisor Assigned",
      score: score,
    };
  });
  // Defines the columns of the DataGrid table with their names and settings.
  const columns = [
    { field: "id", headerName: "ID", width: 70, type: "number" },
    { field: "date", headerName: "Date", width: 200 },
    { field: "area", headerName: "Area", width: 200 },
    { field: "supervisor", headerName: "Supervisor", width: 150 },
    { field: "score", headerName: "Score", width: 120, type: "number" },
  ];

  return (
    <>
      <div className={styles.header}>
        <Button variant="outlined" startIcon={<BarChart />}>Statistics</Button>
        <Box>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "50px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settingsUser.map((item) => (
              <MenuItem
                component={NavLink}
                to={item.path}
                key={item.title}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
      
      <Grid container sx={{ padding: "2rem 0rem 2rem", maxWidth: "auto" }}>
        <Grid item xs={12} sx={{marginLeft: "10%"}}>
          <Typography
            component="h3"
            sx={{
              fontSize: "2.5rem",
              fontFamily: "sans-serif",
              fontWeight: 600,
              textAlign: "justify",
              padding: "3rem 1rem 1rem",
            }}
          >
            Audit Table
          </Typography>
        </Grid>

        <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2.5rem",
        }}
      >
        <div>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Grid>


      <Grid
        item
        xs={12}
        md={8}
        sx={{
          marginBottom: "2.5rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></Grid>

        <Grid item xs={12} sx={{marginLeft: "10%"}}>
          <Typography
            component="h3"
            sx={{
              fontSize: "2.5rem",
              fontFamily: "sans-serif",
              fontWeight: 600,
              textAlign: "justify",
              padding: "3rem 1rem 1rem",
            }}
          >
            Audit Charts by Area
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{marginLeft: "10%", marginBottom: '1rem'}}>
          Select the preferred area
        </Grid>
        
       

        {/* Centering the charts */}
      <Grid item xs={12} md={6} sx={{ display: "flex", alignSelf: "center", marginBottom: "2rem", marginLeft: '15%' }}>
      <form sx={{marginLeft: '20%' }}>
          <Select
            value={selectedArea}
            onChange={(e) => {
              setSelectedArea(e.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="">All areas</MenuItem>
            {[...new Set(audits.map((audit) => audit.area_id))].map(
              (uniqueName) => (
                <MenuItem key={uniqueName} value={uniqueName}>
                  {uniqueName}
                </MenuItem>
              )
            )}
          </Select>
        </form>
        {dataBarChartFilter.length > 0 && (
          <BarChartCustom data={dataBarChartFilter} />
        )}
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", marginBottom: "2rem", marginLeft: '20%' }}>
        {dataBarChartFilter.length > 0 && (
          <RadarChart data={dataBarChartFilter} />
        )}
      </Grid>
      </Grid>

      <br />
    
      
    </>
  );
}
