// import { useState, useEffect, useRef } from 'react';
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
// import SiteForm from '../components/5SCompanyForm';
// import { useLocation } from 'react-router-dom';
// import styles from "./DashboardVSM.module.css";
// import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import { NavLink } from "react-router-dom";
// import Button from '@mui/material/Button';
// import { BarChart } from "@mui/icons-material";
// import { Grid } from "@mui/material";
// import background from '../assets/Benefits_VSM.png'
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';



// const settingsUser = [
//   { title: "New Audit", path: "/newAudit5S" },
//   { title: "Setting", path: "/5Ssettings" },
// ];

// export default function DashboardVSM() {
//   const [anchorElUser, setAnchorElUser] = useState(null);
//   const history = useNavigate();

//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   const handleClick = () => {
//     history.push('/mapInfosVSM');
//   };

//   return (
//     <div>
//       <div className={styles.header}>
        
//         <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>

//         <Box>
//           <Tooltip title="Open Settings">
//             <IconButton onClick={handleOpenUserMenu}>
//               <PersonIcon />
//             </IconButton>
//           </Tooltip>
//           <Menu
//             sx={{ mt: "50px" }}
//             anchorEl={anchorElUser}
//             anchorOrigin={{ vertical: "top", horizontal: "right" }}
//             keepMounted
//             transformOrigin={{ vertical: "top", horizontal: "right" }}
//             open={Boolean(anchorElUser)}
//             onClose={handleCloseUserMenu}
//           >
//             {settingsUser.map((item) => (
//               <MenuItem
//                 component={NavLink}
//                 to={item.path}
//                 key={item.title}
//                 onClick={handleCloseUserMenu}
//               >
//                 <Typography textAlign="center">{item.title}</Typography>
//               </MenuItem>
//             ))}
//           </Menu>
//         </Box>
//       </div>

//       <div className={styles.tabContainer}>
//         <ul>
//           <li><Button>Map Infos</Button></li>
//           <li><Button>Supplier</Button></li>
//           <li><Button>Customer</Button></li>
//           <li><Button>Process Creation</Button></li>
//           <li><Button>Inventory</Button></li>
//           <li><Button>Material Flow Data</Button></li>
//           <li><Button>Informational Flow Data</Button></li>
//         </ul>
//       </div>
//       <img src= {background} alt="background" className={styles.backgroundSection} />
//       <div className={styles.centerContainer} >

//         <Link to="/mapInfosVSM">
//       <Button 
//   sx={{
//     display: 'inline-block',
//     padding: '20px 40px',
//     backgroundColor: '#F24D45',
//     color: '#fff',
//     fontSize: '27px',
//     fontWeight: 'bold',
//     textDecoration: 'none',
//     border: 'none',
//     borderRadius: '5px',
//     boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//     transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
//     fontFamily: 'Arial, Helvetica, sans-serif',
//     '&:hover': {
//       backgroundColor: '#1C253D',
//       transform: 'translateY(-2px)',
//       boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
//     }
//   }}
// >
//   Start The Process!
// </Button>
// </Link>
//       </div>

//     </div>
//   );
// }


import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink, Link } from "react-router-dom";
import { BarChart } from "@mui/icons-material";
import styles from "./DashboardVSM.module.css";
import background from '../assets/Benefits_VSM.png';

import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useLocation } from 'react-router-dom';
import { Grid } from "@mui/material";
import SiteForm from '../components/VSMCompanyForm';


const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default function DashboardVSM() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  // const [showPopup, setShowPopup] = useState(false);
  // const location = useLocation();
  // const popup = useRef(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const popupStyle = {
  //   width: "fit-content",
  //   maxWidth: "80%",
  //   height: "fit-content",
  //   maxHeight: "80%",
  //   padding: "4%",
  // };

  // useEffect(() => {
  //   // Check if the previous page was the sign-in page
  //   const previousPageWasSignIn = sessionStorage.getItem('previousPage') === '/signin';

  //   if (previousPageWasSignIn) {
  //     setShowPopup(true);
  //     sessionStorage.removeItem('previousPage'); // Clear the previous page from sessionStorage
  //   }
  // }, [location]);

  // const fetchCompanyData = () => {
  //   popup.current.close()
  // }

  return (
    <div>
      <div className={styles.header}>
      {/* <Popup
          modal
          open={showPopup}
          ref={popup}
          contentStyle={popupStyle}
          onClose={() => setShowPopup(false)}
          closeOnDocumentClick={false}
        >
          <SiteForm onSubmit={fetchCompanyData}/>
        </Popup> */}

        <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button>Map Infos</Button></li>
          <li><Button>Supplier</Button></li>
          <li><Button>Customer</Button></li>
          <li><Button>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>

      <img src={background} alt="background" className={styles.backgroundSection} />
      <div className={styles.centerContainer}>
        <Link to="/mapInfosVSM">
          <Button 
            sx={{
              display: 'inline-block',
              padding: '20px 40px',
              backgroundColor: '#F24D45',
              color: '#fff',
              fontSize: '27px',
              fontWeight: 'bold',
              textDecoration: 'none',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
              fontFamily: 'Arial, Helvetica, sans-serif',
              '&:hover': {
                backgroundColor: '#1C253D',
                transform: 'translateY(-2px)',
                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
              }
            }}
          >
            Start The Process!
          </Button>
        </Link>
      </div>
    </div>
  );
}
