import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function RadarChart(datas) {
  const data = datas.data;

  if (!Array.isArray(data)) {
    return <div>Invalid data format</div>;
  }
  
  // Select the last four items from the data array
  const lastFourData = data.slice(Math.max(data.length - 4, 0));

  // Define the labels for the radar chart
  const labels = ["Sort", "Set", "Shine", "Standardize", "Sustain"];

  // Function to get the color for each dataset
  const getColor = (i) => {
    let color_num = [
      `rgba(255, 99, 132, 0.7)`,
      `rgba(227, 74, 10, 0.7)`,
      `rgba(57, 104, 239, 0.7)`,
      `rgba(200, 209, 211, 0.7)`,
      `rgba(97, 194, 93, 0.7)`,
    ];
    return color_num[i];
  };

  // Map the last four data items to create datasets for the radar chart
  const datasets = lastFourData.map((audit, index) => ({
    label: `Score Audit ${audit.id}`,
    data: [
      audit.score_sort,
      audit.score_set,
      audit.score_shine,
      audit.score_standardize,
      audit.score_sustain,
    ],
    backgroundColor: getColor(index),
    borderWidth: 1,
  }));

  // Prepare the data structure for the radar chart
  const radarChartData = {
    labels: labels,
    datasets: datasets,
  };

  // Render the radar chart with the specified data
  return <Radar data={radarChartData} />;
}
