import { Button, MenuItem, Select, Stack } from "@mui/material";
import { MultiStepContext } from "../StepContext";
import { useContext } from "react";

const SetInOrder = () => {
  // Access context for step control and user data
  const { setStep, userData, setUserData } = useContext(MultiStepContext);

  return (
    <form>
      <Stack spacing={2}>
        <label>
          Are shelves, storage areas clearly defined with location indicators
          etc. fully marked?
        </label>
        <Select
          // Set initial value from user data
          value={userData["question06"]}
          onChange={(e) =>
            // Update user data on change
            setUserData({ ...userData, question06: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>

          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are shadow boards, tool lockers and other storage systems used where
          possible?
        </label>
        <Select
          value={userData["question07"]}
          onChange={(e) =>
            setUserData({ ...userData, question07: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are walkways & storage clean, unobstructed & clearly marked?
        </label>
        <Select
          value={userData["question08"]}
          onChange={(e) =>
            setUserData({ ...userData, question08: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are personal belongings stored in lockers provided? (if no lockers,
          must be stored tidily in allocated area)
        </label>
        <Select
          value={userData["question09"]}
          onChange={(e) =>
            setUserData({ ...userData, question09: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Safety equipment - Are fire extinguishers & other eqpt in place and
          correct?
        </label>
        <Select
          value={userData["question10"]}
          onChange={(e) =>
            setUserData({ ...userData, question10: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setStep(1)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Back
          </Button>

          <Button
            onClick={() => setStep(3)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default SetInOrder;
