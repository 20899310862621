import styles from "./page.03.module.css"
//import Nav from "../components/Nav"
import { Link } from "react-router-dom"
import { auth } from '../firebase_setup/firebase';


export default function NoAccess() {


  return (
    <div className={styles.landing}>

      <div className={styles.bodycontent}>
      <div className={styles.logo_intro}>
        <img src={require("../assets/Logo_PiTech Academy-02 - Transparent.png")} alt="PiTech Academy"></img>
      </div>
        <div className={styles.textcontent}>
        <h1 style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>No Access</h1>

        <p style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>You need to be a member to access this content.<br/> Please contact PiTechAcademy for info.</p>
        <Link className={styles.register} onClick={() => auth.signOut()} to="/" style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Back to Home</Link>
      </div>
      
      </div>


      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
    </div>
  )
}

