import React, { createContext, useContext, useState } from 'react';

// Define the props interface if needed
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for the customer material flow component
const CustomerMaterialFlowContext = createContext(undefined);

// Provider for the context
export const CustomerMaterialFlowProvider = ({ children }) => {
  const [CusformData, setCusFormData] = useState({
    modeCustomer: "Select an Option",
    periodShiftCustomer: null,
    quantityShiftCustomer: null
  });

  const updateCusFormData = (data) => {
    setCusFormData({ ...CusformData, ...data });
  };

  return (
    <CustomerMaterialFlowContext.Provider value={{ CusformData, updateCusFormData }}>
      {children}
    </CustomerMaterialFlowContext.Provider>
  );
};

// Hook to use the context
export const useCustomerMaterialFlowContext = () => {
  const context = useContext(CustomerMaterialFlowContext);
  if (!context) {
    throw new Error('useCustomerMaterialFlowContext must be used within a CustomerMaterialFlowProvider');
  }
  return context;
};
