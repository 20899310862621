import React, { createContext, useContext, useState } from 'react';

// Define the initial form values
const initialFormValues = {
  enterpriseName: '',
  creatorName: '',
};

// Create the context for the MapInfo component
const MapInfoContext = createContext(undefined);

// MapInfoProvider component
export const MapInfoProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialFormValues);

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  return (
    <MapInfoContext.Provider value={{ formData, updateFormData }}>
      {children}
    </MapInfoContext.Provider>
  );
};

// Custom hook to use the MapInfo context
export const useMapInfoContext = () => {
  const context = useContext(MapInfoContext);
  if (!context) {
    throw new Error('useMapInfoContext must be used within a MapInfoProvider');
  }
  return context;
};
