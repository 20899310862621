/*import {useState, useEffect, useRef} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SiteForm from '../components/5SCompanyForm';
import { db, auth } from '../firebase_setup/firebase';
import { collection, getDocs } from "firebase/firestore";
import Site from '../components/Site';
import styles from "./Dashboard5S.module.css"
import { useMount } from 'react-use'; // useMount from react-use
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BarChart } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation } from 'react-router-dom';




const settingsUser = [
  { title: "New Audit", path: "/newAudit" },
  { title: "Setting", path: "/settings" },
];

export default function Dashboard() {
  //sessionStorage.setItem('type', 'Maturity')
  //console.log(sessionStorage)
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const popup = useRef(null);

  useMount(() => {
    if (popup.current) {
      popup.current.open(); // Trigger the popup to open
    }
  });

  const popupStyle = {
    width: "fit-content",
    maxWidth: "80%",
    height: "fit-content",
    maxHeight: "80%",
    padding: "4%",
  }

  const fetchCompanyData = () => {
    popup.current.close()
  }

  useEffect(() => {
    // Check if the previous page was the sign-in page
    const previousPageWasSignIn = sessionStorage.getItem('previousPage') === '/signin';

    if (previousPageWasSignIn) {
      setShowPopup(true);
      sessionStorage.removeItem('previousPage'); // Clear the previous page from sessionStorage
    }
  }, [location]);


  return (
    <div>
      <div className={styles.header}>
        <Popup 
          modal 
          ref={popup} 
          contentStyle={popupStyle}
          onClose={() => setShowPopup(false)}
          closeOnDocumentClick={false}
        >
          <SiteForm onSubmit={fetchCompanyData}/>
        </Popup>

        <Button variant="outlined" startIcon={<BarChart />}>Statistics</Button>

        <Box>
              <Tooltip title="Open Settings">
                <IconButton onClick={handleOpenUserMenu}>
                  <PersonIcon></PersonIcon>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "50px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settingsUser.map((item) => (
                  <MenuItem
                    component={NavLink}
                    to={item.path}
                    key={item.title}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">{item.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
      </div>
    </div>
  )
} */


import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SiteForm from '../components/5SCompanyForm';
import { useLocation } from 'react-router-dom';
import styles from "./Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { BarChart } from "@mui/icons-material";
import { Grid } from "@mui/material";
import HeroImg2 from "../assets/5sAudit.jpg"



const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default function Dashboard() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const popup = useRef(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const popupStyle = {
    width: "fit-content",
    maxWidth: "80%",
    height: "fit-content",
    maxHeight: "80%",
    padding: "4%",
  };

  useEffect(() => {
    // Check if the previous page was the sign-in page
    const previousPageWasSignIn = sessionStorage.getItem('previousPage') === '/signin';

    if (previousPageWasSignIn) {
      setShowPopup(true);
      sessionStorage.removeItem('previousPage'); // Clear the previous page from sessionStorage
    }
  }, [location]);

  const fetchCompanyData = () => {
    popup.current.close()
  }

  return (
    <div>
      <div className={styles.header}>
        <Popup
          modal
          open={showPopup}
          ref={popup}
          contentStyle={popupStyle}
          onClose={() => setShowPopup(false)}
          closeOnDocumentClick={false}
        >
          <SiteForm onSubmit={fetchCompanyData}/>
        </Popup>

        <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>

        <Box>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "50px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settingsUser.map((item) => (
              <MenuItem
                component={NavLink}
                to={item.path}
                key={item.title}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>

      <Grid container sx={{ padding: "0rem 0rem 2rem" }}>
        <Grid item md={6} sx={{marginTop: "3rem"}}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontSize: "4.5rem",
                fontFamily: "sans-serif",
                fontWeight: 600,
                textAlign: "left",
                padding: "3rem 1rem 1rem",
                marginLeft: "2rem"
              }}
            >
              5S Audit Checklist
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              sx={{
                fontSize: "1.2rem",
                fontFamily: "sans-serif",
                textAlign: "justify",
                padding: "1rem 1rem 1rem",
                marginLeft: "2rem"
              }}
            >
              Discover the latest tools to improve the organization in your
              company and learn to transform your work with innovation.
            </Typography>
          </Grid>
        </Grid>
        <Grid
  item
  md={6}
  component="img"
  sx={{ 
    textAlign: "center",
    width: "30%", // adjust the width to make the image smaller
    maxWidth: "100%", // ensure it doesn't exceed the container's width
    height: "auto" // maintain aspect ratio
  }}
  src={HeroImg2}
/>
      </Grid>
    </div>
  );
}
