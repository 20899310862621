import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";

import { useFieldArray,  } from 'react-hook-form'
import { useSupplierContext } from '../contexts/SupplierContext';
import { useEffect } from 'react';
import { useAllSupplierContext } from '../contexts/SupHandlerContext';
import { useCustomerContext } from '../contexts/CustomerContext';


export default function VSMMapInfos() {

  const navigate = useNavigate(); // Instancia o hook useNavigate
  const {customerForm, updateCustomerForm} = useCustomerContext();
  const { register, formState, handleSubmit } = useForm({ defaultValues: customerForm });
  const {errors} = formState;

  const onSubmit = async (data) =>{
    try{
      console.log('Form Submitted:', data);
      updateCustomerForm(data);
      navigate('/processVSM'); // Redireciona para a página Sup1.html
    }
    catch(error){
      console.log("Error submitting form", error)
    }

  }

  const handlePrevious = () =>{
    navigate('/supplierVSM')
  }

    

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Supplier</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Customer</Button></li>
          <li><Button>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>

        <div className="tab">

          <form id="customerForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
          <div className="buttons-div">
                        <div className='previousButton'>
                            <button type="button" onClick={handlePrevious}>Previous</button>
                        </div>
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
          <br/><br/>
          <h2>Customer</h2>
            <br />
            <label htmlFor="CustomerName">Customer Name:</label>
            <input type="text" id="CustomerName" {...register("CustomerName",{
                required:{
                    value:true,
                    message:"Customer Name is Required"
                }
            })}/>
            <p className='errorsValidation'>{errors.CustomerName?.message}</p>
            <br />
            <label htmlFor="demand">Daily Demand:</label>
            <input type="text" id="demand" {...register("demand", {
              required:{
                value:true,
                message:"Daily Demand is Required"
              }
            })} />
            <p className='errorsValidation'>{errors.demand?.message}</p>
            <br />

          </form>
        </div>
    </div>
  );
}
