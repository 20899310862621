import React, { createContext, useContext, useState } from 'react';
// Import the type or interface from the infoFlow file if needed

// Interface for component props
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for process production
const AllProcProdContext = createContext({
  ProcProds: [],
  updateProcProd: () => {},
});

// Custom hook to use the process production context
export const useAllProcProdContext = () => {
  const context = useContext(AllProcProdContext);
  if (!context) {
    throw new Error('useAllProcProdContext must be used within a ProcProdProvider');
  }
  return context;
};

// Provider for the process production context
export const AllProcProdProvider = ({ children }) => {
  const [ProcProds, setProcProds] = useState([]);

  // Function to update a process production by index
  const updateProcProd = (index, newProcProd) => {
    if (index === 0) {
      setProcProds([newProcProd]);
    } else {
      setProcProds((prevProcProds) => {
        // Create a new array with the updated process production at the specified index
        const updatedProcProds = [...prevProcProds];
        updatedProcProds[index] = newProcProd;
        return updatedProcProds;
      });
    }
  };

  // Value provided to consumers of the process production context
  const contextValue = {
    ProcProds,
    updateProcProd,
  };

  return (
    <AllProcProdContext.Provider value={contextValue}>
      {children}
    </AllProcProdContext.Provider>
  );
};
