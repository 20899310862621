// import { useState, useEffect, useRef } from 'react';
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
// import SiteForm from '../components/5SCompanyForm';
// import { useLocation } from 'react-router-dom';
// import styles from "./DashboardVSM.module.css";
// import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import { NavLink } from "react-router-dom";
// import Button from '@mui/material/Button';
// import { BarChart } from "@mui/icons-material";
// import { Grid } from "@mui/material";
// import HeroImg2 from "../assets/5sAudit.jpg"
// import background from '../assets/Benefits_VSM.png'
// import React from 'react';
// import {useForm} from 'react-hook-form'
// import { useNavigate } from 'react-router-dom'; // Importa o hook useNavigate
// import { useMapInfoContext } from '../components/AllMapInfoContext';


// const settingsUser = [
//   { title: "New Audit", path: "/newAudit5S" },
//   { title: "Setting", path: "/5Ssettings" },
// ];


// export default function Dashboard() {
//   const [anchorElUser, setAnchorElUser] = useState(null);

//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   const navigate = useNavigate(); // Instancia o hook useNavigate
//   const { formData, updateFormData } = useMapInfoContext(); // Use o contexto do componente MapInfo
//   const { register, formState, handleSubmit} = useForm({ defaultValues: formData });
//   const {errors} = formState;

//   const onSubmit = async (data) => {
//     try {
//       console.log('Form Submitted:', data);
//       updateFormData(data)
//       navigate('/Supplier')
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };



//   return (
//     <div>
//       <div className={styles.header}>
        
//         <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>

//         <Box>
//           <Tooltip title="Open Settings">
//             <IconButton onClick={handleOpenUserMenu}>
//               <PersonIcon />
//             </IconButton>
//           </Tooltip>
//           <Menu
//             sx={{ mt: "50px" }}
//             anchorEl={anchorElUser}
//             anchorOrigin={{ vertical: "top", horizontal: "right" }}
//             keepMounted
//             transformOrigin={{ vertical: "top", horizontal: "right" }}
//             open={Boolean(anchorElUser)}
//             onClose={handleCloseUserMenu}
//           >
//             {settingsUser.map((item) => (
//               <MenuItem
//                 component={NavLink}
//                 to={item.path}
//                 key={item.title}
//                 onClick={handleCloseUserMenu}
//               >
//                 <Typography textAlign="center">{item.title}</Typography>
//               </MenuItem>
//             ))}
//           </Menu>
//         </Box>
//       </div>

//       <div className={styles.tabContainer}>
//         <ul>
//           <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)' }}>Map Infos</Button></li>
//           <li><Button>Supplier</Button></li>
//           <li><Button>Customer</Button></li>
//           <li><Button>Process Creation</Button></li>
//           <li><Button>Inventory</Button></li>
//           <li><Button>Material Flow Data</Button></li>
//           <li><Button>Informational Flow Data</Button></li>
//         </ul>
//       </div>
//         {/*}
//       <div className={styles.tab}>

// <form id="mapInfoForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
//   <div className={styles.flexContainer}>
//     <button type="submit">Submit / Next</button>
//   </div>
//   <br/><br/>

// <h2>Basic Information</h2>
//   <br /><br/>
//   <label htmlFor="enterpriseName">Enterprise Name:</label>
//   <input type="text" id="enterpriseName" {...register("enterpriseName")}/>
//   <br />
//   <label htmlFor="creatorName">Creator Name:</label>
//   <input type="text" id="creatorName" {...register("creatorName",{
//     required: {
//       value: true,
//       message: "Creator Name is Required",
//     },
//   })} />
//   <p className={styles.errorsValidation}>{errors.creatorName?.message}</p>
//   <br />
// </form>
// </div> */}


//     </div>
//   );
// }

import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMapInfoContext } from '../contexts/AllMapInfoContext';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";
import '../styles/tabsCSS.css';


export default function VSMMapInfos() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { formData, updateFormData } = useMapInfoContext();
  const { register, formState, handleSubmit } = useForm({ defaultValues: formData });
  const { errors } = formState;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onSubmit = async (data) => {
    try {
      console.log('Form Submitted:', data);
      updateFormData(data);
      navigate('/supplierVSM');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button>Supplier</Button></li>
          <li><Button>Customer</Button></li>
          <li><Button>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>



      <div className="tab">
        <form id="mapInfoForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <div className="buttons-div">
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
          <br /><br />

          <h2>Basic Information</h2>
          <br /><br />
          <label htmlFor="enterpriseName">Enterprise Name:</label>
          <input type="text" id="enterpriseName" {...register("enterpriseName")} />
          <br />
          <label htmlFor="creatorName">Creator Name:</label>
          <input type="text" id="creatorName" {...register("creatorName", {
            required: {
              value: true,
              message: "Creator Name is Required",
            },
          })} />
          <p className='errorsValidation'>{errors.creatorName?.message}</p>
          <br />
        </form>
      </div>
    </div>
  );
}
