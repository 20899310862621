import React from "react";
import styles from "./Question.module.css"

export default function Question(props) {

  const choices = props.answers.map((answer, index) => {
    return (
      <label key={index}>
        <input 
          type="radio"
          name={props.question}
          checked={props.value === index}
          onChange={() => props.onChange(props.dim, props.question, index)}
        />
        <span className={styles.answer}>{answer}</span>
      </label>
    )
  })

  return (
    <div className={styles.question}>
      <b>{props.question}</b>
      <div className={styles.choices}>
        {choices}
      </div>
    </div>
  )
};