import React, { createContext, useState, useContext } from 'react';

// Define the props interface if needed
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Create the context state type
const ProcessContext = createContext(null);

// Provider for the context
export const ProcessProvider = ({ children }) => {
  const [numberOfProcess, setNumberOfProcess] = useState(1);  // CHANGED FROM 0 TO 1

  // Function to update the value of "numberOfProcess"
  const updateNumberOfProcess = (newNumberOfProcess) => {
    setNumberOfProcess(newNumberOfProcess);
  };

  // Value to be provided to consumers of the context
  const contextValue = {
    numberOfProcess,
    updateNumberOfProcess,
  };

  return (
    <ProcessContext.Provider value={contextValue}>
      {children}
    </ProcessContext.Provider>
  );
};

// Hook to use the context
export const useProcessContext = () => {
  const context = useContext(ProcessContext);

  if (!context) {
    throw new Error('useProcessContext must be used within a ProcessProvider');
  }

  return context;
};
