import { useState } from 'react';
import { auth, db } from "../firebase_setup/firebase";
import { doc, setDoc } from "firebase/firestore";
import industries from "../data/industries.json";
import styles from "./5SCompanyForm.module.css";
import {useEffect, useRef} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { collection, getDocs } from "firebase/firestore";
import Site from '../components/Site';
import { useMount } from 'react-use'; // useMount from react-use

export default function SiteForm(props) {

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function fetchCompanies() {
      try {
        const querySnapshot = await getDocs(collection(db, "users", auth.currentUser.uid, "5SCompanies"));
        let fetchedCompanies = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          fetchedCompanies.push(data);
        });
        setCompanies(fetchedCompanies);
        setLoading(false); 
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    }
    
    fetchCompanies().catch(console.error);
  }, []);

  const [formData, setFormData] = useState({
    company: "",
  })

  const [formDataSelect, setFormDataSelect] = useState({
    company: "",
  })

  function handleChange(e) {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    })
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {


      //await setDoc(doc(db, "users", auth.currentUser.uid, "5SCompanies", formData.company), formData);
      const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", formData.company);

      // Define the data object including additional fields
      const data = {
        // Include your existing formData
        ...formData,
        // Additional fields
        audits: [],
        auditAreas: [],
        supervisors: []
      };
  
      // Set the document with the updated data
      await setDoc(docRef, data);
      sessionStorage.setItem('companyChosen', formData.company);
      props.onSubmit();
    } catch(err) {
      console.error("Error adding document: ", err);
    }
  }

  /*
  async function handleSubmitSelect(e) {
    const selectedCompany = e.target.value;
  setFormDataSelect({
    company: selectedCompany,
  });
    try {
      sessionStorage.setItem('companyChosen', formDataSelect.company);
      console.log("Successfully added document: ", formDataSelect.company);
      props.onSubmit();
    } catch(err) {
      console.error("Error selecting company: ", err);
    }
  } */

    function handleSelectChange(e) {
      const selectedCompany = e.target.value;
      setFormDataSelect({ company: selectedCompany });
      // Automatically submit when the select value changes
      handleSubmitSelect(selectedCompany);
    }
  
    async function handleSubmitSelect(selectedCompany) {
      try {
        sessionStorage.setItem('companyChosen', selectedCompany);
        console.log("Successfully selected company: ", selectedCompany);
        props.onSubmit();
      } catch(err) {
        console.error("Error selecting company: ", err);
      }
    }

  return (
    <div className={styles.page}>
      <h2>Add/Choose Company</h2>
      {loading ? (
        <p>Loading companies...</p>
      ) : ( <>
      {companies.forEach((company, index) => {
      console.log(`Company ${index + 1}: ${company.company}`);
    })}
      <p style={{color:"red"}}></p>
      <form className={styles.form} tabIndex={0}>
        <label className={styles.label}>
          <span>Add a New Company</span>
          <input
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </label>
        <button className={styles.submit} onClick={handleSubmit}>SUBMIT</button>
        OR
        <label className={styles.label}>
          <select
                name="companySelect"
                value={formDataSelect.company}
                onChange={handleSelectChange}
                className={styles.select}
              >
                <option value="" disabled>Select a company</option>
                {companies.map((company, index) => (
                  <option key={index} value={company.company}>{company.company}</option>
                ))}
              </select>
        </label>
    </form>
    </> )}
  </div>
  )
}