import styles from "./page.02.module.css"
//import HeroImg3 from "../assets/Slide3.PNG"
//import HeroImg2 from "../assets/Slide2.PNG"
//import { useInView } from 'react-intersection-observer'
import Nav from "../components/Nav"


export default function Progresslanding() {
  //const { ref: pic1Ref, inView: myPic1IsVisible } = useInView();
  //const { ref: pic2Ref, inView: myPic2IsVisible } = useInView();


  return (
    <div className={styles.progresslanding}>
      <div className={styles.progresslandingmain}>
      <Nav/>

      
        <div className={styles.info_header}><h1 style={{
        fontFamily:"'Titillium Web', sans-serif",
        marginBottom: 0
        }}>Our Partners</h1></div>
        


      <div className={styles.partners_box}>
      <div className={styles.partners_items}>
      <img src={require("../assets/smart.png")} alt="Partners"></img>
      {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu elit purus.</p> */}
      </div>
      <div className={styles.partners_items}>
      <img src={require("../assets/SmartFactory-TEC.jpg")} alt="Partners"></img>
      {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu elit purus.</p> */}
      </div>
      <div className={styles.partners_items}>
      <img src={require("../assets/tec.png")} alt="Partners"></img>
      {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu elit purus.</p> */}
      </div>
      <div className={styles.partners_items}>
      <img src={require("../assets/ualberta.png")} alt="Partners"></img>
      {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu elit purus.</p> */}
      </div>
      </div>
      </div>


      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
    </div>
  )
}

