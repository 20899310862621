import React, { createContext, useContext, useState } from 'react';

// Define the props interface if needed
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the type for the context
const AllCusProdContext = createContext({
  CusProds: [],
  updateCusProd: () => {},
});

// Hook to use the context
export const useAllCusProdContext = () => {
  const context = useContext(AllCusProdContext);
  if (!context) {
    throw new Error('useAllCusProdContext must be used within a CusProdProvider');
  }
  return context;
};

// Provider for the context
export const AllCusProdProvider = ({ children }) => {
  const [CusProds, setCusProds] = useState([]);

  const updateCusProd = (index, newCusProd) => {
    if (index === 0) {
      setCusProds([]);
    }

    setCusProds((prevCusProds) => {
      // Create a copy of the previous CusProds array to avoid mutation
      const updatedCusProds = [...prevCusProds];
      updatedCusProds[index] = newCusProd;
      // Return the updated array
      return updatedCusProds;
    });
  };

  return (
    <AllCusProdContext.Provider value={{ CusProds, updateCusProd }}>
      {children}
    </AllCusProdContext.Provider>
  );
};

// Export components individually if needed
export { MyComponentProps, AllCusProdContext };
