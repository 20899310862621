import styles from "./ProgressBar.module.css"

export default function ProgressBar(props) {
  return (
    <div>
      <div 
        className={styles.bar}
        style={{backgroundImage: `linear-gradient(to right, rgba(0,0,0,0) ${props.percentage}%, #dddddd ${props.percentage}%)`}}
      ></div>
      <div className={styles.node_container}>
        {props.sectionLabels.map((label, idx) => {
          return (
            <div key={idx} className={styles.node}>
              <div
                className={styles.circle}
                style={props.percentage >= (idx+1)*100/props.sectionLabels.length ? {backgroundColor:"#3A7DFF"} : {}}
                onClick={() => props.setQuestion(props.sectionStarts[idx])}
              ></div>
              <p>{label}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}