import React, { createContext, useContext, useState } from 'react';

// Define the form values type for the Customer component
const CustomerFormValues = {
  CustomerName: '',
  demand: '',
};

// Define the props interface if needed
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the type for the context
const CustomerContext = createContext(undefined);

// Provider for the context
export const CustomerProvider = ({ children }) => {
  const [customerForm, setCustomerForm] = useState(CustomerFormValues);

  const updateCustomerForm = (data) => {
    setCustomerForm({ ...customerForm, ...data });
  };

  return (
    <CustomerContext.Provider value={{ customerForm, updateCustomerForm }}>
      {children}
    </CustomerContext.Provider>
  );
};

// Hook to use the context
export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomerContext must be used within a CustomerProvider');
  }
  return context;
};
