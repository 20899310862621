// import { Button, MenuItem, Select, Stack } from "@mui/material";
// import { useContext, useEffect, useState } from "react";
// import { MultiStepContext } from "../StepContext";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { doc, getDoc, collection, addDoc } from "firebase/firestore";
// import { auth, db } from "../../../firebase_setup/firebase";

// const Details = ({ companyID }) => {
//   // Context access for step control and user data
//   const { setStep, userData, setUserData } = useContext(MultiStepContext);

//   // State variables for supervisors and areas data (likely fetched from API)
//   const [supervisors, setSupervisors] = useState([]);
//   const [areas, setAreas] = useState([]);


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const companyName = sessionStorage.getItem('companyChosen');
//         const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           const { auditAreas, supervisors, ...otherData } = data;
//           setSupervisors(supervisors || []);
//           setAreas(auditAreas || []);
//         } else {
//           console.log("No such document!");
//         }
//       } catch (err) {
//         console.error("Error getting document:", err);
//       }
//     };

//     fetchData();
//   }, []);

//   // Handles date change using the DatePicker component
//   const handleDateChange = (date) => {
//     setUserData({ ...userData, date });
//   };

//   return (
//     <form>
//       <Stack spacing={2}>
//         <label>Area Audit</label>
//         <Select
//           value={userData["area_audit"]}
//           onChange={(e) =>
//             setUserData({ ...userData, area_audit: e.target.value })
//           }
//         >
//           {areas.map((area) => (
//             <MenuItem key={area.id} value={area.name}>
//               {area.name}
//             </MenuItem>
//           ))}
//         </Select>

//         <label>Audit Completed by</label>
//         <Select
//           value={userData["supervisor"]}
//           onChange={(e) =>
//             setUserData({ ...userData, supervisor: e.target.value })
//           }
//         >
//           {supervisors.map((supervisor) => (
//             <MenuItem key={supervisor.id} value={supervisor.name}>
//               {supervisor.name}
//             </MenuItem>
//           ))}
//         </Select>

//         <label>Date</label>

//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <DatePicker
//             value={userData["date"]}
//             onChange={handleDateChange}
//           ></DatePicker>
//         </LocalizationProvider>

//         <Button onClick={() => setStep(1)} variant="contained" color="primary">
//           Next
//         </Button>
//       </Stack>
//     </form>
//   );
// };

// export default Details;
import { Button, MenuItem, Select, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MultiStepContext } from "../StepContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase_setup/firebase";

const Details = () => {
  // Context access for step control and user data
  const { setStep, userData, setUserData } = useContext(MultiStepContext);


  // State variables for supervisors and areas data (likely fetched from API)
  const [supervisors, setSupervisors] = useState([]);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const { auditAreas, supervisors, ...otherData } = data;
          setSupervisors(supervisors || []);
          setAreas(auditAreas || []);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };

    fetchData();
  }, []);

  // Handles date change using the DatePicker component
  const handleDateChange = (date) => {
    setUserData({ ...userData, date });
  };

  if (userData === null || userData === undefined) {
    // Handle the case where currentStep is not yet initialized
    return <div>Loading...</div>;
  }

  return (
    <form>
      <Stack spacing={2}>
        <label>Area Audit</label>
        <Select
  value={userData["area_audit"]}
  onChange={(e) =>
    setUserData({ ...userData, area_audit: e.target.value })
  }
>
  {areas.map((area, index) => (
    <MenuItem key={`area_${index}`} value={area}>
      {area}
    </MenuItem>
  ))}
</Select>

<Select
  value={userData["supervisor"]}
  onChange={(e) =>
    setUserData({ ...userData, supervisor: e.target.value })
  }
>
  {supervisors.map((supervisor, index) => (
    <MenuItem key={`supervisor_${index}`} value={supervisor}>
      {supervisor}
    </MenuItem>
  ))}
</Select>

        <label>Date</label>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={userData["date"]}
            onChange={handleDateChange}
          ></DatePicker>
        </LocalizationProvider>

        <Button onClick={() => setStep(1)} variant="contained" color="primary">
          Next
        </Button>
      </Stack>
    </form>
  );
};

export default Details;
