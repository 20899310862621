import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChartCustom(datas) {
  // Calculate the scores by summing up the different score components
  const data = datas.data;
  const scores = data.map(
    (item) =>
      item.score_sort +
      item.score_set +
      item.score_shine +
      item.score_standardize +
      item.score_sustain
  );
  // Extract the labels (ids) from the data
  const labels = data.map((item) => item.id);

  // Prepare the data structure for the bar chart
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Score",
        data: scores,
        backgroundColor: "blue",
      },
    ],
  };

  // Configure the options for the bar chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Scores of Audits",
      },
    },
  };

  // Render the bar chart with the specified data and options
  return <Bar options={options} data={barChartData} />;
}
