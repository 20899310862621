import React, { createContext, useContext, useState } from 'react';
// Import the type or interface from the Process file if needed
// import { FormValues } from '../components/Process'; 

const FormValues = {
  proNumbers: [
      {
          processName: '',
          cycleTime: null,
          availableTime: null,
          upTime: null,
          scrapRate: null
      }
  ]
};

// Interface for component props
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for processes
const AllProcessContext = createContext({
  processes: [],
  updateProcess: () => {},
});

// Custom hook to use the process context
export const useAllProcessContext = () => {
  const context = useContext(AllProcessContext);
  if (!context) {
    throw new Error('useAllProcessContext must be used within a ProcessProvider');
  }
  return context;
};

// Provider for the process context
export const AllProcessProvider = ({ children }) => {
  const [processes, setProcesses] = useState(FormValues.proNumbers);   // CHANGED FROM USING [] in usestate

  // Function to update a process by index
  const updateProcess = (index, newProcess) => {
    if (index === 0) {
      console.log("HEREEEEEEEEEEEEEE1")
      setProcesses([newProcess]);
    } else {
      console.log("HEREEEEEEEEEEEEEE2")
      setProcesses((prevProcesses) => {
        // Create a new array with the updated process at the specified index
        const updatedProcesses = [...prevProcesses];
        updatedProcesses[index] = newProcess;
        return updatedProcesses;
      });
    }
  };

  // Value provided to consumers of the process context
  const contextValue = {
    processes,
    updateProcess,
  };

  return (
    <AllProcessContext.Provider value={contextValue}>
      {children}
    </AllProcessContext.Provider>
  );
};
