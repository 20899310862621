import { useState, useLayoutEffect, useEffect } from "react";
import maturity_model from "../data/maturity_model.json"
import Question from  "../components/Question"
import { auth, db } from "../firebase_setup/firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./Assessment.module.css"
import ProgressBar from "../components/ProgressBar";
import DimSummary from "../components/DimSummary";

export default function Assessment() {
  const { site } = useParams()
  const navigate = useNavigate()

  // Form state. Each property is a maturity model dimension, which stores an
  // object that maps each question to the respondents answer/score
  const [formData, setFormData] = useState(() => {
    let form = JSON.parse(localStorage.getItem(site))
    if (form === null) {
      form = {}
      let dimensions = maturity_model.dimensions
      for (var dim in dimensions) {
        form[dim] = {}
        for (var i = 0; i < dimensions[dim].length; i++) {
          let question = dimensions[dim][i]
          form[dim][question] = -1
        }
      }
    }
    return form
  })

  // Store form data locally on page unload/refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem(site, JSON.stringify(formData));
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleBeforeUnload();
    };
  }, [site, formData]);

  // Question/Page states
  const [question, setQuestion] = useState(0)
  const [questionsPerPage, setQuestionsPerPage] = useState(3)

  // JSX array of survey Question components loaded from maturity model
  const questions = (() => {
    var questions = []
    const answers = maturity_model.levels
    for (var dim in formData) {
      let index = 0
      for (var question in formData[dim]) {
        questions.push(
          <Question
            key={index}
            dim={dim}
            question={question}
            answers={answers}
            value={formData[dim][question]}
            onChange={handleFormChange}
        />)
        index++
      }
    }
    return questions
  })()

  // Determine # of questions to display depending on window size
  useLayoutEffect(() => {
    function updateSize() {
      //FIXME should be dynamic depending on Question component height
      const childHeight = 220
      setQuestionsPerPage(Math.max(Math.floor((window.innerHeight-childHeight)/childHeight), 1))
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Object, each key is a dimensions, each value is a Boolean
  // (whether that dim's questions have ALL been answered)
  const [dimsComplete, setDimsComplete] = useState(() => {
    let dims = Object.keys(formData)
    let dimState = {}
    dims.forEach(dim => dimState[dim] = false)
    return dimState
  })

  // Handlers
  async function handleSubmit() {
    try {
      // Submit response
      await setDoc(doc(db, "users", auth.currentUser.uid, "responses", site), formData);
      // Mark site as submitted
      await updateDoc(doc(db, "users", auth.currentUser.uid, "sites", site), {"completed": true});
      localStorage.removeItem(site)
      navigate("/dashboard")
    } catch(err) {
      console.error("Error submitting form: ", err)
    }
  }

  function handleFormChange(dim, question, score) {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [dim]: {
          ...prevFormData[dim],
          [question]: parseInt(score)
        }
      }
    })
  }

  function handlePrevPage() {
    setQuestion(prevNum => Math.max(prevNum - questionsPerPage, 0))
  }

  function handleNextPage() {
    setQuestion(prevNum => prevNum + questionsPerPage)
  }

  function handleDimCompleted(dim) {
    setDimsComplete(prev => {
      return {...prev, [dim]: true}
    })
  }

  //FIXME: can make the website more efficient by removing redundant loads
  // and adding properties to the maturity_model.json file
  // const [dimStarts, setDimStarts] = useState([])
  // const [totalQuestions, setTotalQuestions] = useState()
  // useEffect(() => {
  //   setDimStarts([])
  //   for (let dim in formData) {
  //     var count = Object.keys(formData[dim]).length
  //     setDimStarts(prev => [...prev, count + prev[prev.length-1] || 0])
  //   }
  //   setTotalQuestions(dimStarts.reduce((a, b) => a + b, 0))
  // }, [formData])
    // NO NEED TO BE DYNAMIC... could be stored in maturity_model.json
  const dimStarts = [0, 9, 18, 27, 36]
  const totalQuestions = 41

  return (
    <div className={styles.page}>
      <ProgressBar
        percentage={question/totalQuestions*100}
        sectionLabels={Object.keys(formData)}
        sectionStarts={dimStarts}
        setQuestion={setQuestion}
      />
      {question < questions.length ?
        // QUESTIONS
        <div>
          <div className={styles.questions}>
            { questions.slice(question, question + questionsPerPage) }
          </div>
          <div className={styles.question_nav}>
            { question > 0 && <button onClick={handlePrevPage}>PREVIOUS</button> }
            <button onClick={handleNextPage}>NEXT</button>
          </div>
        </div>
        : 
        // SUBMIT PAGE
        <div>
          <div className={styles.summary}>
            <h1>Confirm Your Responses</h1>
            <div>
              {Object.keys(formData).map((dim, idx) => 
                <DimSummary
                  key={idx}
                  formData={formData}
                  dim={dim}
                  dimCompleted={dimsComplete[dim]}
                  handleComplete={handleDimCompleted}
                />
              )}
            </div>
          </div>
          <div className={styles.question_nav}>
            <button onClick={handlePrevPage}>PREVIOUS</button>
            <button onClick={handleSubmit} disabled={!Object.values(dimsComplete).every(x=>x)}>SUBMIT</button>
          </div>
        </div>
      }
    </div>
  );
}
