import {useState, useEffect, useRef} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SiteForm from '../components/SiteForm';
import { db, auth } from '../firebase_setup/firebase';
import { collection, getDocs } from "firebase/firestore";
import Site from '../components/Site';
import styles from "./Dashboard.module.css"


export default function Dashboard() {
  //sessionStorage.setItem('type', 'Maturity')
  //console.log(sessionStorage)
  const [siteIDs, setSiteIDs] = useState([])
  const [siteDocs, setSiteDocs] = useState([])
  const [sitesAdded, setSitesAdded] = useState(0)

  // Fetches user's manufacturing sites from Firebase
  useEffect(() => {
    async function fetchSites() {
      try {
        setSiteIDs([])
        setSiteDocs([])
        const querySnapshot = await getDocs(collection(db, "users", auth.currentUser.uid, "sites"));
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          setSiteIDs(prevIDs => [...prevIDs, data.site])
          setSiteDocs(oldSites => [...oldSites, data])
        });
      } catch {
        console.log("No user")
      }
    }

    fetchSites().catch(console.error)
  }, [sitesAdded])

  // JSX button, trigger for new site popup
  const newSiteButton = () => {
    return (
      <button className={styles.addButton}>
        <h1>+</h1>
        <p>Add new site</p>
      </button>
    )
  }

  const popup = useRef();
  const popupStyle = {
    width: "fit-content",
    maxWidth: "80%",
    height: "fit-content",
    maxHeight: "80%",
    padding: "4%",
  }

  const addNewSite = () => {
    popup.current.close()
    setSitesAdded(prev => prev + 1)
  }

  return (
    <div>
      <div className={styles.header}>
        <h2>Welcome, {auth.currentUser.displayName}.</h2>
        <Popup 
          modal 
          ref={popup} 
          trigger={newSiteButton()}
          contentStyle={popupStyle}
        >
          <SiteForm siteIDs={siteIDs} onSubmit={addNewSite}/>
        </Popup>
      </div>
      <div className={styles.grid}>
        {siteDocs && siteDocs.map((doc, idx) => <Site key={idx} doc={doc}/>)}
      </div>
      {
      auth.currentUser && auth.currentUser.email === 'smartlab@ualberta.ca' && (
        <a href='vg6rkKvqUM/MembersSignUp'>Link for new members to sign up</a>
        )
    }
    </div>
  )
}