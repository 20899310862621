import { Button, MenuItem, Select, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { MultiStepContext } from "../StepContext";
// import { useForm } from "react-hook-form";

const Sort = () => {
  const { setStep, userData, setUserData } = useContext(MultiStepContext);
  const [errorMessage, setErrorMessage] = useState("");

  const handleNextClick = () => {
    // Verificar si alguna pregunta no ha sido respondida
    const unansweredQuestions = Object.keys(userData).filter(
      (key) => userData[key] === -1
    );

    if (unansweredQuestions.length > 20) {
      // Mostrar mensaje de error si hay preguntas sin responder
      setErrorMessage("Please answer all questions.");
    } else {
      // Si todas las preguntas han sido respondidas, avanzar a la siguiente página
      setErrorMessage(""); // Limpiar el mensaje de error
      setStep(2);
    }
  };

  // const { register } = useForm();

  return (
    <>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

      <Stack spacing={2}>
        <label>
          Have all unnecessary items (tools, equipment, boxes etc.) been removed
          from the area?
        </label>
        <Select
          value={userData["question01"]}
          onChange={
            (e) => setUserData({ ...userData, question01: e.target.value }) // ..user --> todo lo que está en userData hasta ese momento
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are all remaining items (tools, eqpt, trolleys etc.) neatly arranged?
        </label>
        <Select
          value={userData["question02"]}
          onChange={(e) =>
            setUserData({ ...userData, question02: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are tools & equipment in the most convenient location - tools at hand
          & workstations easy to access?
        </label>
        <Select
          value={userData["question03"]}
          onChange={(e) =>
            setUserData({ ...userData, question03: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Do cupboards, storage cabinets & other enclosed areas only contain
          essential items?
        </label>
        <Select
          value={userData["question04"]}
          onChange={(e) =>
            setUserData({ ...userData, question04: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Is the quarantine area properly managed? (no quaratine are, score
          zero)
        </label>
        <Select
          value={userData["question05"]}
          onChange={(e) =>
            setUserData({ ...userData, question05: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setStep(0)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Back
          </Button>

          <Button
            onClick={handleNextClick}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Sort;
