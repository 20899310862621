// import React from 'react';
// import { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
// import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
// import { NavLink } from 'react-router-dom';
// import { BarChart } from '@mui/icons-material';
// import styles from "./DashboardVSM.module.css";

// import { useFieldArray,  } from 'react-hook-form'
// import { useSupplierContext } from '../contexts/SupplierContext';
// import { useEffect } from 'react';

// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, doc, setDoc, addDoc } from 'firebase/firestore';
// import { getDoc } from "firebase/firestore";
// import { auth, db } from "../firebase_setup/firebase";


// import { useAllCusProdContext } from '../contexts/CusProdContext';
// import { useAllProcProdContext } from '../contexts/ProProdContext';
// import { useAllSupProdContext } from '../contexts/SupProdContext';
// import { useMapInfoContext } from '../contexts/AllMapInfoContext';
// import { useAllSupplierContext } from '../contexts/SupHandlerContext';
// import { useCustomerContext } from '../contexts/CustomerContext';
// import { useAllProcessContext } from '../contexts/ProHandlerContext';
// import { useAllInventoryContext } from '../contexts/InventoryContext';
// import { useCustomerMaterialFlowContext } from '../contexts/CustomerMatContext';
// import { useAllSupMatContext } from '../contexts/SupMatContext';

// const DataSubmissionComponent = () => {
//   const { CusProds } = useAllCusProdContext();
//   const { SupProds } = useAllSupProdContext();
//   const { ProcProds } = useAllProcProdContext();
//   const { formData } = useMapInfoContext();
//   const { suppliers } = useAllSupplierContext();
//   const { customerForm } = useCustomerContext();
//   const { processes } = useAllProcessContext();
//   const { inventories } = useAllInventoryContext();
//   const { SupMats } = useAllSupMatContext();
//   const { CusformData } = useCustomerMaterialFlowContext();
//   const navigate = useNavigate();

  
//   const handleSubmitData = async () => {
//     try {
//       const data = {
//         CusProds,
//         SupProds,
//         ProcProds,
//         formData,
//         suppliers,
//         customerForm,
//         processes,
//         inventories,
//         SupMats,
//         CusformData,
//       };
//       console.log("data: ", data)

//       const enterpriseName = formData.enterpriseName;

//       if (!enterpriseName) {
//         throw new Error('Enterprise name is required.');
//       }

//       const vsmCompaniesRef = db.collection('VSMCompanies');
//       const enterpriseDocRef = vsmCompaniesRef.doc(enterpriseName);

//       await enterpriseDocRef.set({});
//       const dataRef = enterpriseDocRef.collection('data');
//       await dataRef.add(data);


//       const auditRef = collection(db, "users", auth.currentUser.uid, "VSMCompanies", enterpriseName);
//       const auditDocRef = await addDoc(auditRef, {
//         data: data
//       });

//       console.log("Audit submission result:", auditDocRef);


//       // Faça a requisição POST para enviar os dados para o servidor
//       //const resposta = await axios.post('http://localhost:3000/api/enviar-dados', dados);

//       //console.log(resposta.data); // Exibir resposta do servidor
//       navigate('/resultVSM');
//     } catch (error) {
//       console.error('Erro ao enviar dados para o backend:', error);
//     }
//   };

//   const handlePrevious = () => {
//     navigate('/infoFlowVSM');
//   };

//   return (
//     <div>
//       <button className="previous-button" onClick={handlePrevious}>Previous Page</button>
//       <button className="submit-button" onClick={handleSubmitData}>Confirm and Submit Form</button>
//     </div>
//   );
// };

// export default DataSubmissionComponent;



import React from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, addDoc } from 'firebase/firestore';
import { auth, db } from "../firebase_setup/firebase";
import { useAllCusProdContext } from '../contexts/CusProdContext';
import { useAllSupProdContext } from '../contexts/SupProdContext';
import { useAllProcProdContext } from '../contexts/ProProdContext';
import { useMapInfoContext } from '../contexts/AllMapInfoContext';
import { useAllSupplierContext } from '../contexts/SupHandlerContext';
import { useCustomerContext } from '../contexts/CustomerContext';
import { useAllProcessContext } from '../contexts/ProHandlerContext';
import { useAllInventoryContext } from '../contexts/InventoryContext';
import { useAllSupMatContext } from '../contexts/SupMatContext';
import { useCustomerMaterialFlowContext } from '../contexts/CustomerMatContext';

const DataSubmissionComponent = () => {
  const { CusProds } = useAllCusProdContext();
  const { SupProds } = useAllSupProdContext();
  const { ProcProds } = useAllProcProdContext();
  const { formData } = useMapInfoContext();
  const { suppliers } = useAllSupplierContext();
  const { customerForm } = useCustomerContext();
  const { processes } = useAllProcessContext();
  const { inventories } = useAllInventoryContext();
  const { SupMats } = useAllSupMatContext();
  const { CusformData } = useCustomerMaterialFlowContext();
  const navigate = useNavigate();

  const handleSubmitData = async () => {
    try {
      const data = {
        CusProds,
        SupProds,
        ProcProds,
        formData,
        suppliers,
        customerForm,
        processes,
        inventories,
        SupMats,
        CusformData,
      };
      console.log("data: ", data);

      // const enterpriseName = formData.enterpriseName;

      // if (!enterpriseName) {
      //   throw new Error('Enterprise name is required.');
      // }

      // const vsmCompaniesRef = collection(db, 'VSMCompanies');
      // const enterpriseDocRef = doc(vsmCompaniesRef, enterpriseName);

      // await setDoc(enterpriseDocRef, {});
      // const dataRef = collection(enterpriseDocRef, 'data');
      // await addDoc(dataRef, data);

      // if (auth.currentUser) {
      //   const auditRef = collection(db, "users", auth.currentUser.uid, "VSMCompanies");
      //   const auditDocRef = await addDoc(auditRef, data);

      //   console.log("Audit submission result:", auditDocRef);
      // } else {
      //   throw new Error('User is not authenticated.');
      // }

      const enterpriseName = formData.enterpriseName;

      if (!enterpriseName) {
        throw new Error('Enterprise name is required.');
      }

      // Reference to the VSMCompanies collection and create a document with enterpriseName
      const vsmCompaniesRef = collection(db, 'VSMCompanies');
      const enterpriseDocRef = doc(vsmCompaniesRef, enterpriseName);

      // Set the data to the specified document ID
      await setDoc(enterpriseDocRef, {});

      // Set the data under the 'data' subcollection with enterpriseName as the document ID
      const dataRef = collection(enterpriseDocRef, 'data');
      await setDoc(doc(dataRef, enterpriseName), data);

      if (auth.currentUser) {
        const auditRef = collection(db, "users", auth.currentUser.uid, "VSMCompanies");
        // Set the data to a document with enterpriseName as the document ID
        await setDoc(doc(auditRef, enterpriseName), data);

        console.log("Audit submission result: Document with ID", enterpriseName, "has been created.");
      } else {
        throw new Error('User is not authenticated.');
      }

      sessionStorage.setItem('companyChosenVSM', enterpriseName);
      navigate('/resultVSM');
    } catch (error) {
      console.error('Error while submitting data:', error);
    }
  };

  const handlePrevious = () => {
    navigate('/infoFlowVSM');
  };

  return (
    <div>
      <button className="previous-button" onClick={handlePrevious}>Previous Page</button>
      <button className="submit-button" onClick={handleSubmitData}>Confirm and Submit Form</button>
    </div>
  );
};

export default DataSubmissionComponent;
