import React, { createContext, useContext, useState } from 'react';

// Define the props interface if needed
const MyComponentProps = ({ children }) => {
  // Other props can be managed here
  return (
    <div>
      {children}
    </div>
  );
};

// Define the context type for the inventory component
const AllInventoryContext = createContext(undefined);

// Provider for the context
export const AllInventoryProvider = ({ children }) => {
  const [inventories, setInventories] = useState([]);

  const updateInventory = (index, newInventory) => {
    if (index === 0) {
      setInventories([newInventory]);
    } else {
      setInventories((prevInventories) => {
        // Create a copy of the previous inventories array to avoid mutation
        const updatedInventories = [...prevInventories];
        updatedInventories[index] = newInventory;
        // Return the updated array
        return updatedInventories;
      });
    }
  };

  return (
    <AllInventoryContext.Provider value={{ inventories, updateInventory }}>
      {children}
    </AllInventoryContext.Provider>
  );
};

// Hook to use the context
export const useAllInventoryContext = () => {
  const context = useContext(AllInventoryContext);
  if (!context) {
    throw new Error('useAllInventoryContext must be used within a AllInventoryProvider');
  }
  return context;
};
