import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Define the initial state and context
const initialState = {
  numberOfSuppliers: 0,
  updateNumberOfSuppliers: () => {},
};

// Create the context
export const SupplierContext = createContext(initialState);

// Create the provider for the context
export const SupplierProvider = ({ children }) => {
  const [numberOfSuppliers, setNumberOfSuppliers] = useState(initialState.numberOfSuppliers);

  // Function to update the number of suppliers
  const updateNumberOfSuppliers = (newNumberOfSuppliers) => {
    setNumberOfSuppliers(newNumberOfSuppliers);
  };

  // Value to be provided to consumers of the context
  const contextValue = {
    numberOfSuppliers,
    updateNumberOfSuppliers,
  };

  return (
    <SupplierContext.Provider value={contextValue}>
      {children}
    </SupplierContext.Provider>
  );
};

SupplierProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Create the hook to consume the context
export const useSupplierContext = () => {
  const context = useContext(SupplierContext);

  if (!context) {
    throw new Error('useSupplierContext must be used within a SupplierProvider');
  }

  return context;
};
