import styles from "./Replies.module.css";
import Nav from "../components/Nav";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getDocs, query } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";

const firestore = getFirestore();

const addThreadToFirestore = async (threadList) => {
    const threadCollectionRef = collection(firestore, 'threadArray');
  // FIX DELETING THE THREAD IN THE COLLECTION
    const data = await fetchThreadData();
    const documentId = data[0].id;
    console.log("IDDD: ", documentId);
    console.log("Dataaaaa: ", data);
    // Create a reference to the document
    const documentRef = doc(firestore, "threadArray", documentId);

    // Delete the document
    deleteDoc(documentRef)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error deleting document:', error);
      });
    
    await addDoc(threadCollectionRef, { "threadList": threadList });
    console.log("done")
};

const fetchThreadData = async () => {
    try {
      // Reference to the "userData" subcollection for the current user
      const threadCollectionRef = collection(getFirestore(), 'threadArray');
  
      // Fetch documents from the "userData" subcollection
      const userDataQuery = query(threadCollectionRef);
      const userDataSnapshot = await getDocs(userDataQuery);
      console.log('accessed');
      // Check if the subcollection has any documents
      if (!userDataSnapshot.empty) {
        // Subcollection exists, extract and set data
        const data = userDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log(data);
        return data;
      } else {
        // Subcollection does not exist or is empty
        console.log('The "userData" subcollection does not exist or is empty.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

export default function Progresslanding() {
  //const { ref: pic1Ref, inView: myPic1IsVisible } = useInView();
  //const { ref: pic2Ref, inView: myPic2IsVisible } = useInView();
    const [replyList, setReplyList] = useState([]);
    const [reply, setReply] = useState("");
    const [display_Name, setdisplayName] = useState("");
    const [title, setTitle] = useState("");
    //const navigate = useNavigate();
    const { id } = useParams();
    const [threadList, setThreadList] = useState([]);
    //const [buttonClickTime, setButtonClickTime] = useState(null);
    const [time, setTitleTime] = useState("");
    let properDate = null;


    useEffect(() => {
        const fetchReplies = async () => {
            try {
                const fetchedData = await fetchThreadData();
                console.log("dataaa: ", fetchedData);
                setThreadList(fetchedData[0].threadList);
            } catch (error) {
                console.error('Error fetching and setting thread data:', error);
            }
        };
    
        fetchReplies();
    }, [id]);
    
    useEffect(() => {
        const filterAndLog = async () => {
            try {
                // Wait for threadList to be updated
                const updatedThreadList = await fetchThreadData();
                setThreadList(updatedThreadList[0].threadList);
    
                // Filter the threadList and log the result
                const result = updatedThreadList[0].threadList.filter((thread) => thread.id === id);
                console.log(result[0]);
                if (result && result.length > 0) {
                    console.log("WORKING: ", result[0].replies);
                    setReplyList(result[0].replies);
                    setTitle(result[0].title);
                    setdisplayName(result[0].displayName);
                    setTitleTime(result[0].time);
                } else {
                    console.error('Result array is undefined or empty.');
                }
            } catch (error) {
                console.error('Error filtering threadList:', error);
            }
        };
    
        // Run the filtering logic when threadList changes
        filterAndLog();
    }, [id]);

    const addReply = () => {
        const result = threadList.filter((thread) => thread.id === id);
        let username = sessionStorage.getItem('displayName');
        result[0].replies.unshift({
            name: username,
            text: reply,
            time: properDate
        });
        console.log("RESULTTTTTTT: ", result)
        console.log(threadList)
        addThreadToFirestore(threadList)
        .then(() => {
        // Role added successfully
        console.log('Thread added successfully.');
        fetchThreadData();
        // Fetch documents from the "userData" subcollection
        })
        .catch((error) => {
        // Handle errors
        console.log('Error:', error);
        });
    };


    const handleSubmitReply = (e) => {
        e.preventDefault();
        console.log("TRHEAD: ", threadList);
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const year = currentDate.getFullYear();
        const monthNames = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
          ];
        
          // Extract the month as a string
        const monthName = monthNames[currentDate.getMonth()];
        properDate = monthName + " " + dayOfMonth + ", " + year
        console.log("CURRENTDATE: ", properDate);
        //setButtonClickTime(currentDate);
        addReply();
        setReply("");
    };

    return (
        <div className={styles.progresslanding}>
        <div className={styles.progresslandingmain}>
        <Nav/>
            <div className={styles.replies}>
            <h1 className={styles.repliesTitle}>{title.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</h1>
            <p style={{ opacity: "0.5" }} className={styles.react__container_p}>by {display_Name}<br></br>- {time}</p>
                <form className={styles.modal__content} onSubmit={handleSubmitReply}>
                    <label htmlFor='reply'>Reply to the thread</label>
                    <textarea
                        rows={5}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        type='text'
                        name='reply'
                        className={styles.modalInput}
                    />

                    <button className={styles.modalBtn}>SEND</button>
                </form>
                <div className={styles.thread__container}>
            {replyList.map((reply) => (
                <div className={styles.thread__item}>
                    <p>{reply.text.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</p>
                    <div className={styles.react__container}>
                        <p style={{ opacity: "0.5" }} className={styles.react__container_p}>by {reply.name}<br></br><div className={styles.react__container_time}>- {reply.time}</div></p>
                    </div>
                </div>
            ))}
        </div>
            </div>

        </div>


        <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
        </div>
    )
}

