import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";

import { useFieldArray,  } from 'react-hook-form'
import { useSupplierContext } from '../contexts/SupplierContext';
import { useEffect } from 'react';
import { useAllSupplierContext } from '../contexts/SupHandlerContext';

export default function VSMMapInfos() {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const { suppliers, updateSupplier } = useAllSupplierContext();
    const { numberOfSuppliers, updateNumberOfSuppliers } = useSupplierContext();
    const { register, control, formState, handleSubmit, setValue } = useForm({
        defaultValues: {
            supNumbers: suppliers.length > 0 ? suppliers : [{ supplierName: '', whatSupplies: '' }] // Verifica se há fornecedores; se não, adiciona um fornecedor vazio
        }
    });
    const { errors } = formState;
    const navigate = useNavigate(); // Instancia o hook useNavigate

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'supNumbers'
    });

    useEffect(() => {
        if (numberOfSuppliers === 0) {
            handleAppendAndIncrement();
            const updatedSupplier = {
                supplierName: '',
                whatSupplies: ''
            };
            console.log(updatedSupplier)
            updateSupplier(0, updatedSupplier);
        }
    }, []);

    useEffect(() => {
        // Atualizar os valores do formulário com os valores dos fornecedores sempre que eles mudarem
        setValue('supNumbers', suppliers);
    }, [suppliers, setValue]);

    const onSubmit = async (data) => {
        try {
            parentToChild();
            const newNumberOfSuppliers = numberOfSuppliers;
            updateNumberOfSuppliers(newNumberOfSuppliers);
            for (let i = 0; i < numberOfSuppliers; i++) {
                const updatedSupplier = {
                    supplierName: data.supNumbers[i].supplierName,
                    whatSupplies: data.supNumbers[i].whatSupplies
                };
                updateSupplier(i, updatedSupplier);
                console.log("Supplier:", updatedSupplier, "atualizado na posição:", i)
            }
            navigate('/customerVSM')
            
        }
        catch (error) {
            console.log('Error submiting form:', error);
        }
    };

    
    const handleAppendAndIncrement = () => {
        console.log(suppliers)
        append({ supplierName: "", whatSupplies: "" });
        
        // Incrementa o índice
        updateNumberOfSuppliers(numberOfSuppliers + 1);
    };

    
    const handleRemoveAndDecrement = (index) => {
        // Remove o processo usando o índice fornecido
        remove(index);

        // Decrementa o índice
        updateNumberOfSuppliers(numberOfSuppliers - 1);
    };

    const parentToChild = () => {
        updateNumberOfSuppliers(numberOfSuppliers);
    };

    const handlePrevious = () => {
        navigate('/mapInfosVSM')
    }
    

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Supplier</Button></li>
          <li><Button>Customer</Button></li>
          <li><Button>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>
            
                <form id="supplierForm" onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off" noValidate>
                    <div className="tab">
                        <div className="buttons-div">
                        <div className='previousButton'>
                            <button type="button" onClick={handlePrevious}>Previous</button>
                        </div>
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
                        <br /><br />
                        <div>
                            
                            {fields.map((field, index) => (
                                <div className='form-control' key={field.id}>
                                    <h2>Supplier Number {index + 1}</h2>
                                    <br /><br />
                                    <label htmlFor={`supNumbers.${index}.supplierName`}>Supplier Name:</label>
                                    <input
                                        type="text"
                                        {...register(`supNumbers.${index}.supplierName`, {
                                            required: {
                                                value: true,
                                                message: "Supplier Name is Required",
                                            },
                                        })}
                                    />
                                    <p className='errorsValidation'>{errors?.supNumbers?.[index]?.supplierName?.message}</p>
                                    <br />
                                    <label htmlFor={`supNumbers.${index}.whatSupplies`}>What it Supplies:</label>
                                    <input
                                        type="text"
                                        id="whatSupplies"
                                        {...register(`supNumbers.${index}.whatSupplies`)}
                                    />
                                    <br />
                                    {
                                        index > 0 && (
                                            <button
                                                type="button"
                                                id="removeSupplierButton"
                                                className="removeSupplierButton"
                                                onClick={() => handleRemoveAndDecrement(index)}
                                            >
                                                Remove Supplier
                                            </button>
                                        )
                                    }
                                    <div className='divisionLine'></div>
                                    <br />
                                </div>

                            ))}
                            <br />
                            <button type="button" id="addSupplierButton" className="addSupplierButton" onClick={handleAppendAndIncrement}>Add Supplier</button>
                        </div>
                    </div>
                </form>
    </div>
  );
}
