import styles from "./Forum.module.css"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase_setup/firebase"
import Nav from "../components/Nav"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getDocs, query } from "firebase/firestore";
//import Likes from "../utils/Likes";
import Comments from "../utils/Comments";
import { doc, deleteDoc } from "firebase/firestore";

const firestore = getFirestore();

const addThreadToFirestore = async (threadList) => {
    const threadCollectionRef = collection(firestore, 'threadArray');
  // FIX DELETING THE THREAD IN THE COLLECTION
    const data = await fetchThreadData();
    const documentId = data[0].id;
    console.log("IDDD: ", documentId);
    console.log("Dataaaaa: ", data);
    // Create a reference to the document
    const documentRef = doc(firestore, "threadArray", documentId);

    // Delete the document
    deleteDoc(documentRef)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error deleting document:', error);
      });
    
    await addDoc(threadCollectionRef, { "threadList": threadList });
    console.log("done")
};

const fetchThreadData = async () => {
  try {
    // Reference to the "userData" subcollection for the current user
    const threadCollectionRef = collection(getFirestore(), 'threadArray');

    // Fetch documents from the "userData" subcollection
    const userDataQuery = query(threadCollectionRef);
    const userDataSnapshot = await getDocs(userDataQuery);
    console.log('accessed');
    // Check if the subcollection has any documents
    if (!userDataSnapshot.empty) {
      // Subcollection exists, extract and set data
      const data = userDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log(data);
      return data;
    } else {
      // Subcollection does not exist or is empty
      console.log('The "userData" subcollection does not exist or is empty.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export default function Progresslanding() {
  //const { ref: pic1Ref, inView: myPic1IsVisible } = useInView();
  //const { ref: pic2Ref, inView: myPic2IsVisible } = useInView();
  const [thread, setThread] = useState("");
  const navigate = useNavigate();
  const [threadList, setThreadList] = useState([]);
  let properDate = null;

  const generateID = () => Math.random().toString(36).substring(2, 10);

  const createThread = () => {
    // FETCH THREADLIST HERE
    let displayName = sessionStorage.getItem('displayName');
    const threadId = generateID();

    threadList.unshift({
      id: threadId,
      title: thread,
      displayName,
      replies: [],
      time: properDate
    })

    console.log("threadlist: ", threadList);

    addThreadToFirestore(threadList)
    .then(() => {
      // Role added successfully
      console.log('Thread added successfully.');
      fetchThreadData();
    // Fetch documents from the "userData" subcollection
    })
    .catch((error) => {
      // Handle errors
      console.log('Error:', error);
    });
  }

  const checkUser = () => {
    if (auth.currentUser === null) {
        navigate("/login");
    } else {
      console.log("authenticatedddd  ", auth.currentUser )
      createThread();
    }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      console.log({ thread });
      const currentDate = new Date();
      const dayOfMonth = currentDate.getDate();
      const year = currentDate.getFullYear();
      const monthNames = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
          ];
        
          // Extract the month as a string
      const monthName = monthNames[currentDate.getMonth()];
      properDate = monthName + " " + dayOfMonth + ", " + year
      checkUser();
      setThread("");
  };

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const data = await fetchThreadData();
        console.log("dataaa: ", data)
        setThreadList(data[0].threadList);
      } catch (error) {
        console.error('Error fetching and setting thread data:', error);
      }
    };
    fetchData(); 
    console.log("data: ", threadList); 
  }, []);

  return (
    <div className={styles.progresslanding}>
      <div className={styles.progresslandingmain}>
      <Nav />
                <h2 className={styles.homeTitle}>Create a Thread</h2>
                <form className={styles.homeForm} onSubmit={handleSubmit}>
                    <div className={styles.home__container}>
                        <label htmlFor='thread'>Title / Description</label>
                        <textarea
                            type='text'
                            name='thread'
                            required
                            value={thread}
                            onChange={(e) => setThread(e.target.value)}
                        />
                    </div>
                    <button className={styles.homeBtn}>CREATE THREAD</button>
                </form>

                <div className={styles.thread__container}>
                {threadList.map((thread) => (
                    <div className={styles.thread__item} key={thread.id}>
                        <p>{thread.title.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}</p>
                        <div className={styles.react__container}>
                            {/*<Likes numberOfLikes={thread.likes.length} threadId={thread.id} /> */}
                            <p style={{ opacity: "0.5" }} className={styles.react__container_p}>by {thread.displayName}<br></br><div className={styles.react__container_time}>- {thread.time}</div></p>
                            <Comments
                                numberOfComments={thread.replies.length}
                                threadId={thread.id}
                title={thread.title}/>
                        </div>
                    </div>
                ))}
            </div>
      </div>


      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
    </div>
  )
}

