import { Button, MenuItem, Select, Stack } from "@mui/material";
import { useContext } from "react";
import { MultiStepContext } from "../StepContext";

const Shine = () => {
  const { setStep, userData, setUserData } = useContext(MultiStepContext);
  return (
    <form>
      <Stack spacing={2}>
        <label>
          Floors are kept clean, tidy and in a good state of repair?
        </label>
        <Select
          value={userData["question11"]}
          onChange={(e) =>
            setUserData({ ...userData, question11: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Is all work equipment clean and tidy - free from build up of dust or
          visible leaks or preventable spillages?
        </label>
        <Select
          value={userData["question12"]}
          onChange={(e) =>
            setUserData({ ...userData, question12: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Bins - Are these properly maintained and regularly emptied?
        </label>
        <Select
          value={userData["question13"]}
          onChange={(e) =>
            setUserData({ ...userData, question13: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Cleaning station - Are all items present? (if no station, score zero)
        </label>
        <Select
          value={userData["question14"]}
          onChange={(e) =>
            setUserData({ ...userData, question14: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Is the cleaning equipment clean and of a good and presentable
          standard?
        </label>
        <Select
          value={userData["question15"]}
          onChange={(e) =>
            setUserData({ ...userData, question15: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setStep(2)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Back
          </Button>

          <Button
            onClick={() => setStep(4)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Shine;
