import { Link } from "react-router-dom"
import styles from "./Site.module.css"

export default function Site(props) {
  // Destructure fields from Firebase Document
  const {company, industry, site, employeeRange, location, completed} = props.doc
  const {city, province, country} = location

  return(
    <div className={styles.site}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>{(company.length > 16) ? company.slice(0, 15) + '...' : company}</p>
          {/* // FIXME display score and change color depending on value*/}
          {/* <p style={{color: "#FFB340"}}>{completed ? 2.1 : "-"}</p> */}
        </div>
        <p className={styles.id}>{site}</p>
      </div>
      <div className={styles.details}>
        <p>{city}, {province}, {country}</p>
        <p>{industry}</p>
        <p>{employeeRange} employees</p>
      </div>
      <Link to={completed ? `/results/${site}` : `/assessment/${site}`} className={styles.link}> 
        {completed ? "VIEW RESULTS" : "START ASSESSMENT" }
      </Link>
    </div>
  )
}