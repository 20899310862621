import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "../firebase_setup/firebase";
import SignInScreen from '../pages/SignIn';
import Nav from "./Nav";

// FIXME: quick fix for validating user login. 
// The problem is that every page re-load, the sign-in screen shows briefly. 
// Eventually should have a better way of determining whether user is authorized to
// access the restricted pages
// maybe store log-in state in session storage and load it from there?
export default function PrivateOutlet() {
  const [currentUser, setCurrentUser] = useState(null);
  
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(setCurrentUser);
    return () => unregisterAuthObserver();
  }, []);
  
  return currentUser === null ? <SignInScreen/> : 
    <div>
      <Nav/>
      <Outlet/>
    </div>
};