import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";
import { useFieldArray,  } from 'react-hook-form'
import { useEffect } from 'react';
import { useProcessContext } from '../contexts/ProcessContext';
import { useAllProcessContext } from '../contexts/ProHandlerContext';


export default function VSMProcesses() {

    
  const {processes, updateProcess} = useAllProcessContext();
  const { numberOfProcess, updateNumberOfProcess } = useProcessContext();
  
  const { register, control, formState, handleSubmit, setValue} = useForm({
      defaultValues: {
          proNumbers: processes.length > 0 ? processes : [{ processName: '', cycleTime: null, availableTime: null, upTime: null, scrapRate: null }]
      }
  });

  const { errors } = formState;
  const navigate = useNavigate(); // Instancia o hook useNavigate
  
  const { fields, append, remove } = useFieldArray({
      control,
      name: 'proNumbers'
  });
  
  useEffect(() => {
    console.log(" Length: ", processes.length)
    console.log("numberOfProcess: ", numberOfProcess)
    console.log(processes)
    if (numberOfProcess === 0) { // CHANGED FROM NUMBER OF PROCESSES
          handleAppendAndIncrement();
          const updatedProcess = {
              processName: '',
              cycleTime: null,
              availableTime: null,
              upTime: null,
              scrapRate: null
          }
          console.log(updatedProcess);
          updateProcess(0,updatedProcess);
      }
  }, []);


  useEffect(()=>{
    console.log("processes")
    console.log(processes)
    setValue('proNumbers', processes);
  }, [processes, setValue]);

  const onSubmit = async (data) =>{
      try{
          parentToChild();
          const newNumberOfProcess = numberOfProcess;
          console.log("Number of processes saved:", numberOfProcess)
          updateNumberOfProcess(newNumberOfProcess);
          const updatedProcessesArray = [];
          for(let i = 0; i < numberOfProcess; i++){
              const updatedProcess = {
                  processName: data.proNumbers[i].processName,
                  cycleTime: data.proNumbers[i].cycleTime,
                  availableTime: data.proNumbers[i].availableTime,
                  upTime: data.proNumbers[i].upTime,
                  scrapRate: data.proNumbers[i].scrapRate
              };
              updateProcess(i, updatedProcess);
              console.log("Process:", updatedProcess, "atualizado na posição: ", i)
              updatedProcessesArray.push(updatedProcess);
              setValue('proNumbers', updatedProcess);
              console.log(processes)
          }

          // Convert the updated processes array to a JSON string
            const updatedProcessesJSON = JSON.stringify(updatedProcessesArray);

        // Store the JSON string in session storage
            sessionStorage.setItem('updatedProcesses', updatedProcessesJSON);

          navigate('/inventoryVSM')

      }
      catch(error){
          console.log("Error Submiting Process Form:", error)
      }
  }



  const handleAppendAndIncrement = () => {
      // Adiciona um novo processo usando o append
      append({ processName: '', cycleTime: null, availableTime: null, upTime: null, scrapRate: null });
      
      // Incrementa o índice
      updateNumberOfProcess(numberOfProcess + 1);
      console.log("Called append, number of processes is: ", numberOfProcess);
  };

  const handleRemoveAndDecrement = (index) => {
      // Remove o processo usando o índice fornecido
      remove(index);

      // Decrementa o índice
      updateNumberOfProcess(numberOfProcess - 1);
  };

  const parentToChild = () => {
      updateNumberOfProcess(numberOfProcess);
  }

  const handlePrevious = () => {
      navigate('/customerVSM')
  }
    

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Supplier</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Customer</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>

                <form id="processForm" onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off" noValidate>
                    <div className="tab">
                    <div className="buttons-div">
                        <div className='previousButton'>
                            <button type="button" onClick={handlePrevious}>Previous</button>
                        </div>
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
                        <br /><br />
                        <div>
                            {
                                fields.map((field, index) => {
                                    return (
                                        <div className='form-control' key={field.id}>
                                            <h2>Process Number {index + 1}</h2>
                                            <br /><br />
                                            <label htmlFor={`proNumbers.${index}.processName`}>Process Name:</label>
                                            <input type="text"
                                                {...register(`proNumbers.${index}.processName`, {
                                                    required: {
                                                        value: true,
                                                        message: "Supplier Name is Required",
                                                    },
                                                } )} />
                                            <p className='errorsValidation'>{errors?.proNumbers?.[index]?.processName?.message}</p>
                                            <br />

                                            <label htmlFor={`proNumbers.${index}.cycleTime`}>Cycle Time (seconds):</label>
                                            <input type="number"
                                                {...register(`proNumbers.${index}.cycleTime`, {
                                                    required: {
                                                        value: true,
                                                        message: "Cycle Time is Required",
                                                    },
                                                    validate: value => {
                                                        if (value != null) {
                                                            if (value < 0) {
                                                                return "Cycle Time must be positive!"
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                } )} />
                                            <p className='errorsValidation'>{errors?.proNumbers?.[index]?.cycleTime?.message}</p>
                                            <br />


                                            <label htmlFor={`proNumbers.${index}.availableTime`}>Available Time (seconds):</label>
                                            <input
                                                type="number"
                                                id="availableTime"
                                                {...register(`proNumbers.${index}.availableTime`, {
                                                    validate: value => {
                                                        if (value != null) {
                                                            if (value < 0) {
                                                                return "This value must be positive or empty!";
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                } )} />
                                            <p className='errorsValidation'>{errors?.proNumbers?.[index]?.availableTime?.message}</p>
                                            <br />



                                            <label htmlFor={`proNumbers.${index}.upTime`}>Up Time (%):</label>
                                            <input
                                                type="number"
                                                id="upTime"
                                                {...register(`proNumbers.${index}.upTime`, {
                                                    validate: value => {
                                                        if (value != null) {
                                                            if (value < 0 || value > 100) {
                                                                return "Up Time (%) must be between 0 and 100, or empty!";
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                } )} />
                                            <p className='errorsValidation'>{errors?.proNumbers?.[index]?.upTime?.message}</p>
                                            <br />

                                            <label htmlFor={`proNumbers.${index}.scrapRate`}>Scrap Rate (%):</label>
                                            <input
                                                type="number"
                                                id="scrapRate"
                                                {...register(`proNumbers.${index}.scrapRate`, {
                                                    validate: value => {
                                                        if (value != null) {
                                                            if (value < 0 || value > 100) {
                                                                return "Scrap Rate (%) must be between 0 and 100, or empty!";
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                } )} />
                                            <p className='errorsValidation'>{errors?.proNumbers?.[index]?.scrapRate?.message}</p>
                                            <br />

                                            {

                                                index > 0 && (
                                                    <React.Fragment>
                                                        <button type="button" id="removeProcessButton" className="removeProcessButton" onClick={() => handleRemoveAndDecrement(index)}>Remove Process</button>
                                                        <br />
                                                    </React.Fragment>)
                                            }


                                            <div className='divisionLine'></div>
                                            <br />
                                        </div>
                                    );
                                })}
                            <br />
                            <button type="button" id="addProcessButton" className="addProcessButton" onClick={handleAppendAndIncrement}>Add Process</button>
                        </div>

                    </div>
                </form>

    </div>
  );
}

