import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from '../firebase_setup/firebase';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Link } from 'react-router-dom';
import styles from "./SignIn.module.css";
// import Logo from "../assets/logo-grey-no-bg.png"
import Decoration from "../assets/factory.jpg";
//import PrivateOutlet2 from "../components/PrivateOutlet2";
import { collection, getDocs, query } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
//import { useState, useEffect } from 'react';

//const settype = () => {
//  sessionStorage.setItem('type', 'Member');
//  let data = sessionStorage.getItem('type');
//  console.log(data)
//}






let userData = [];
const fetchUserData = async () => {
  try {
    // Reference to the "userData" subcollection for the current user
    const userDataCollectionRef = collection(getFirestore(), 'userRole', auth.currentUser.uid, 'roles');

    // Fetch documents from the "userData" subcollection
    const userDataQuery = query(userDataCollectionRef);
    const userDataSnapshot = await getDocs(userDataQuery);
    console.log('accessed');
    // Check if the subcollection has any documents
    if (!userDataSnapshot.empty) {
      // Subcollection exists, extract and set data
      const data = userDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      userData = data;
      console.log(data);
    } else {
      // Subcollection does not exist or is empty
      console.log('The "userData" subcollection does not exist or is empty.');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: "/dashboardMembers",
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // Store user data in sessionStorage.
      sessionStorage.setItem('type', 'Member');
      //sessionStorage.setItem('uauth', JSON.stringify(authResult));
      //sessionStorage.setItem('displayName', authResult.user.displayName);
      fetchUserData()
        .then(() => {
          console.log('USER', userData);
        userData.length === 0 ? window.location.assign(redirectUrl || "/noAccess") : window.location.assign(redirectUrl || "/dashboardMembers");
        })
        .catch((error) => {
          // Handle errors
          console.log('Error:', error);
        });
      // Redirect to the specified URL after successful sign-in.
      return false;
    }
  }
};

export default function SignInScreen() {
  //sessionStorage.setItem('type', '')
  return (
    <div className={styles.body}>
      <div className={styles.login}>
        <Link to="/" style={{
        marginLeft: '0px',
      }}>
          <img className={styles.logo} src={require("../assets/PiTechLogo2E2.jpg")} alt="logo"></img>
        </Link>
        <h3 styles={{color: 'white'}}>Transforming your <br/> business just got easier.</h3>
        <span className={styles.title}>Please sign in to continue.</span>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
        
      </div>
      <div className={styles.sidebar}>
        <img className={styles.sidebar_img} src={Decoration} draggable={false} alt=""/>
        <div className={styles.orgs}>
          <div className={styles.logo_container}>
            <img src={require("../assets/smart.png")} alt="SMART LAB Logo"></img>
            <img src={require("../assets/SmartFactory-TEC.jpg")} alt="SMART Factory Logo"></img>
          </div>
          <div className={styles.logo_container}>
            <img src={require("../assets/ualberta.png")} alt="University of Alberta Logo"></img>
            <img src={require("../assets/tec.png")} alt="Tecnológico de Monterrey Logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
} 











/*

const useAuth = () => {
  const [userData, setUserData] = useState([]);

  const fetchUserData = async () => {
    try {
      const userDataCollectionRef = collection(getFirestore(), 'userRole', auth.currentUser.uid, 'roles');
      const userDataQuery = query(userDataCollectionRef);
      const userDataSnapshot = await getDocs(userDataQuery);

      if (!userDataSnapshot.empty) {
        const data = userDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUserData(data);
        console.log('accessed')
        console.log(data);
      } else {
        console.log('The "userData" subcollection does not exist or is empty.');
        console.log(userData);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/dashboardMembers',
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        sessionStorage.setItem('type', 'Member');
        fetchUserData()
          .then(() => {
          console.log(userData)
          //userData.length === 0 ? window.location.assign(redirectUrl || "/") : window.location.assign(redirectUrl || "/dashboardMembers");
          })
          .catch((error) => {
            // Handle errors
            console.log('Error:', error);
          });
        return false;
      }
    }
  };


  return { userData, uiConfig };
};

export default function SignInScreen() {
  const { userData, uiConfig } = useAuth();
  //sessionStorage.setItem('type', '')
  return (
    <div className={styles.body}>
      <div className={styles.login}>
        <Link to="/">
          <img className={styles.logo} src={require("../assets/Logo_PiTech Academy-02 - Transparent.png")} alt="logo"></img>
        </Link>
        <h2>Transforming your <br/> business just got easier.</h2>
        <span className={styles.title}>Please sign in to continue.</span>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
        
      </div>
      <div className={styles.sidebar}>
        <img className={styles.sidebar_img} src={Decoration} draggable={false} alt=""/>
        <div className={styles.orgs}>
          <div className={styles.logo_container}>
            <img src={require("../assets/smart.png")} alt="SMART LAB Logo"></img>
            <img src={require("../assets/SmartFactory-TEC.jpg")} alt="SMART Factory Logo"></img>
          </div>
          <div className={styles.logo_container}>
            <img src={require("../assets/ualberta.png")} alt="University of Alberta Logo"></img>
            <img src={require("../assets/tec.png")} alt="Tecnológico de Monterrey Logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}  */