import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Assessment from "./pages/Assessment"
import SignInScreen from './pages/SignIn';
import SignInScreenMembers from './pages/SignInMembers';
import Dashboard from './pages/Dashboard';
import Dashboard5S from './pages/Dashboard5S';
//import DashboardVSM from './pages/VSMDashboardParent';
//import VSMMapInfos from './pages/VSMMapInfos';
import Dashboard5SStats from './pages/Dashboard5SStats';
import Settings5S from './pages/Settings5S';
import SettingsEdit5S from './pages/SettingsEdit5S';
import SettingsForm5S from './pages/SettingsForm5S';
import NewAudit from "./pages/NewAudit5S";
import DashboardMembers from './pages/DashboardMembers';
import Results from "./pages/Results";
import Landing from "./pages/Landing";
//import Page1 from "./pages/page.01.js";
import Page2 from "./pages/Progresslanding";
import Forum from "./pages/Forum";
import Signup from "./pages/Signup";
import PrivateOutlet from "./components/PrivateOutlet";
//import PrivateOutlet2 from "./components/PrivateOutlet2";
import TESTemailenter from "./pages/TESTemailenter";
import NoAccess from "./pages/NoAccess";
import Replies from "./pages/Replies";
import FreeResourcesHub from "./pages/FreeResourcesHub";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import React from 'react';
import DashboardVSM from "./pages/DashboardVSM";
import VSMMapInfos from './pages/VSMMapInfos';
import VSMSupplier from './pages/VSMSupplier';
import VSMCustomer from './pages/VSMCustomer';
import VSMProcess from './pages/VSMProcess';
import VSMInventory from './pages/VSMInventory';
import VSMMatFlow from './pages/VSMMaterialFlow';
import VSMInfoFlow from './pages/VSMInfoFlow';
import VSMReviewForm from './pages/VSMReviewForm';
import VSMResult from "./pages/VSMResult";
import VSMResultOtherMaps from "./pages/VSMResultOtherMaps";


import { MapInfoProvider } from "./contexts/AllMapInfoContext";
import { AllSupplierProvider } from "./contexts/SupHandlerContext";
import { ProcessProvider } from "./contexts/ProcessContext";
import { SupplierProvider } from "./contexts/SupplierContext";
import { CustomerProvider } from "./contexts/CustomerContext";
import { CustomerMaterialFlowProvider } from "./contexts/CustomerMatContext";
import { AllProcessProvider } from './contexts/ProHandlerContext';
import { AllInventoryProvider } from './contexts/InventoryContext';
import { AllSupMatProvider } from './contexts/SupMatContext';
import { AllCusProdProvider } from './contexts/CusProdContext';
import { AllSupProdProvider } from './contexts/SupProdContext';
import { AllProcProdProvider } from './contexts/ProProdContext';




// Define your theme
const theme = createTheme();



export default function App() {
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/Partners" element={<Page2/>}/>
        <Route path="/Forum" element={<Forum/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/vg6rkKvqUM/MembersSignUp" element={<TESTemailenter/>}/>
        <Route path="/noAccess" element={<NoAccess/>}/>
        <Route path="/loginMembers" element={<SignInScreenMembers/>}/>
        <Route path="/login" element={<SignInScreen/>}/>
        <Route path='/:id/replies' element={<Replies />} />
        <Route path='/freeResources' element={<FreeResourcesHub />}/>
        <Route element={PrivateOutlet()}>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/dashboard5S" element={<Dashboard5S/>}/>
          <Route path="/newAudit5S" element={<NewAudit/>}/>
          <Route path="/5Sstats" element={<Dashboard5SStats/>}/>
          <Route path="/5Ssettings" element={<Settings5S/>}/>
          <Route path="/areaSetting5S/:id/edit" element={<SettingsForm5S/>}/>
          <Route path="/areaSetting5S/add" element={<SettingsForm5S/>}/>
          <Route path="/supervisorSetting5S/:id/edit" element={<SettingsForm5S/>}/>
          <Route path="/supervisorSetting5S/add" element={<SettingsForm5S/>}/>
          <Route path="/areaSetting5S" element={<SettingsEdit5S/>}/>
          <Route path="/supervisorSetting5S" element={<SettingsEdit5S/>}/>
          <Route
              path="/dashboardVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                                              <DashboardVSM />
                                              </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/mapInfosVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                                              <VSMMapInfos />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/supplierVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMSupplier />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
             <Route
              path="/customerVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMCustomer />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/processVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMProcess />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/inventoryVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMInventory />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/matflowVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMMatFlow />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
<Route
              path="/infoFlowVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMInfoFlow />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
  <Route
              path="/reviewFormVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMReviewForm />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />

<Route
              path="/resultVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMResult />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
            <Route
              path="/otherMapsVSM"
              element={
<AllSupProdProvider>
      <AllCusProdProvider>
        <AllProcProdProvider>
          <AllSupMatProvider>
            <AllInventoryProvider>
              <AllProcessProvider>
                <ProcessProvider>
                  <AllSupplierProvider>
                    <SupplierProvider>
                      <MapInfoProvider>
                        <CustomerProvider>
                          <CustomerMaterialFlowProvider>
                  <VSMResultOtherMaps />
                  </CustomerMaterialFlowProvider>
                        </CustomerProvider>
                      </MapInfoProvider>
                    </SupplierProvider>
                  </AllSupplierProvider>
                </ProcessProvider>
              </AllProcessProvider>
            </AllInventoryProvider>
          </AllSupMatProvider>
        </AllProcProdProvider>
      </AllCusProdProvider>
    </AllSupProdProvider>
              }
            />
          <Route path="/dashboardMembers" element={<DashboardMembers/>}/>
          <Route path="/assessment/:site" element={<Assessment/>}/>
          <Route path="/results/:site" element={<Results/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}