import React, { createContext, useContext, useState } from 'react';

// Define the type for the form values
// Assuming FormValues is imported from '../components/Supplier'
const FormValues = {
  supNumbers: []
};

// Define the type for the context
const AllSupplierContext = createContext({
  suppliers: [],
  updateSupplier: () => {},
});

// Hook to use the context
export const useAllSupplierContext = () => {
  const context = useContext(AllSupplierContext);
  if (!context) {
    throw new Error('useAllSupplierContext must be used within a SupplierProvider');
  }
  return context;
};

// Context provider
export const AllSupplierProvider = ({ children }) => {
  const [suppliers, setSuppliers] = useState([]);

  const updateSupplier = (index, newSupplier) => {
    if (index === 0) {
      setSuppliers([]);
    }

    setSuppliers((prevSuppliers) => {
      // Create a copy of the previous suppliers array to avoid mutation
      const updatedSuppliers = [...prevSuppliers];
      updatedSuppliers[index] = newSupplier;
      // Return the updated array
      return updatedSuppliers;
    });
  };

  return (
    <AllSupplierContext.Provider value={{ suppliers, updateSupplier }}>
      {children}
    </AllSupplierContext.Provider>
  );
};
