import { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import StepContext from "../components/newAudit/StepContext.js";
import NewAudit from "../components/newAudit/NewAudit.js";



export default function NewAudit5S() {
  const [company, setCompany] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        setCompany(companyName); // Set the rest of the data as company
          
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
    <StepContext companyID={company}>
      <NewAudit />
    </StepContext>
    </>
  );
}
