import { useState, useEffect } from "react"
import styles from "./DimSummary.module.css"
import RedCross from "../assets/red_x.png"
import GreenCheck from "../assets/green_check.png"

export default function DimSummary(props) {
  const [open, setOpen] = useState(false)
  const [unansweredCount, setUnansweredCount] = useState(0)

  // Create array of (un)answered question JSX elements
  // and determine total number of unanswered q's
  const [questions] = useState(() => {
    let questions = []
    let responses = props.formData[props.dim]
    for (let question in responses) {
      let image = GreenCheck
      if (responses[question] === -1) {
        setUnansweredCount(prev => prev + 1)
        image = RedCross
      }
      questions.push(
        <div key={questions.length} className={styles.response}>
          <p>{question}</p>
          <img className={styles.icon} src={image} alt=""/>
        </div>
      )
    }
    return questions
  })

  useEffect(() => {
    if (!props.dimCompleted && unansweredCount === 0) {
      props.handleComplete(props.dim)
    }
  }, [props, unansweredCount])

  function handleClick() {
    setOpen(prev => !prev)
  }
  
  return (
    <div>
      <div className={styles.dim}>
        <button onClick={handleClick}></button>
        <b>{props.dim}</b>
        <img className={styles.icon} src={unansweredCount > 0 ? RedCross : GreenCheck} alt=""/>
        <p>{unansweredCount > 0 ? `${unansweredCount} questions remaining` : ""}</p>
      </div>
      {open && questions}
    </div>
  )
}