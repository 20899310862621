import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import styles from "./Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import { BarChart, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase_setup/firebase";

const SettingsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let url = location.pathname.split("/");
  let type_data = url[1].substring(0, url[1].length - 9);
  const [areas, setAreas] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [name, setName] = useState('');
  const [dataList, setDataList] = useState([]);

  // Analize which setting is being edited (area or supervisor)
  let type_data_url;
  type_data === "area"
    ? (type_data_url = "areasAudit")
    : (type_data_url = "supervisors");
  let method = url[url.length - 1];

  const [dataForm, setDataForm] = useState({
    name: "",
  });

  let id_edit = url[url.length - 2];

  // Retrieves data for an existing audit area or supervisor based on its ID.
  const loadData = async (id) => {
    try {
      const companyName = sessionStorage.getItem('companyChosen');
      const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        const { auditAreas = [], supervisors = [] } = data;
  
        setSupervisors(supervisors);
        setAreas(auditAreas);
  
        const urlPath = location.pathname.split("/")[1];
        if (urlPath === "areaSetting5S") {
          setName("area");
          setDataList(auditAreas);
        } else {
          setName("supervisor");
          setDataList(supervisors);
        }
  
        // Ensure id is within valid range
        if (urlPath === "areaSetting5S") {
        if (id >= 0 && id < auditAreas.length) {
          setDataForm({ name: auditAreas[id] }); // Assuming auditAreas[id] is the name you want to set
        } else {
          console.error(`Invalid id: ${id}`);
        } } else {
          if (id >= 0 && id < supervisors.length) {
            setDataForm({ name: supervisors[id] }); // Assuming auditAreas[id] is the name you want to set
          } else {
            console.error(`Invalid id: ${id}`);
          }
        }
  
      } else {
        console.log("No such document!");
      }
    } catch (err) {
      console.error("Error getting document:", err);
    }
  };

    useEffect(() => {
      if (id_edit && !isNaN(id_edit)) {
        loadData(id_edit);
      } else {
        console.error("Invalid id_edit:", id_edit);
        // Handle the case where id_edit is not a valid number
      }
    }, [id_edit]);
  

  // Handles form submission.
  // Depending on the method (add or edit), makes a POST or PUT request
  // to create or update an audit area or monitor.
  const formSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const companyName = sessionStorage.getItem('companyChosen');
      const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        let updatedArray;
  
        
        // Edit existing area
        type_data_url === "areasAudit" ? updatedArray = [...data.auditAreas] : updatedArray = [...data.supervisors];
        if (location.pathname.endsWith("add")) {
          updatedArray.push(dataForm.name);
        } else {
        updatedArray[id_edit] = dataForm.name; }
        
        type_data_url === "areasAudit" ? await updateDoc(docRef, { ["auditAreas"]: updatedArray }) : await updateDoc(docRef, { ["supervisors"]: updatedArray });
          
        // // Update local state
        // if (type_data === "area") {
        //   setAreas(updatedArray);
        //   setDataList(updatedArray);
        // } else {
        //   setSupervisors(updatedArray);
        //   setDataList(updatedArray);
        // }

        navigate("/5Ssettings");
  
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  

  // Updates the dataForm state with the value entered in the text field.
  const formChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  return (
    <Grid
      container
      direction="column"
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <Grid item xs={3} sx={{ marginTop: "5rem" }}>
        <Card>
          <h3>
            {method.charAt(0).toUpperCase() + method.slice(1)}
            {" " + type_data}
          </h3>
          <CardContent>
            <form onSubmit={formSubmit}>
              <TextField
                variant="filled"
                label={"Write your " + type_data}
                sx={{ display: "flex", marginBottom: "1rem" }}
                value={dataForm.name}
                name="name"
                onChange={formChange}
              ></TextField>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SettingsForm;
