import { useState, useEffect } from 'react';
import { auth, db } from "../firebase_setup/firebase";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import styles from "./5SCompanyForm.module.css";

export default function SiteForm(props) {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    company: "",
  });
  const [formDataSelect, setFormDataSelect] = useState({
    company: "",
  });

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const querySnapshot = await getDocs(collection(db, "users", auth.currentUser.uid, 'VSMCompanies'));
        // Extract document IDs
        const companyIDs = querySnapshot.docs.map(doc => doc.id);
        setCompanies(companyIDs);
        setLoading(false); 
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    }
    
    fetchCompanies().catch(console.error);
  }, []);

  function handleChange(e) {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", formData.company);

      // Define the data object including additional fields
      const data = {
        ...formData,
        audits: [],
        auditAreas: [],
        supervisors: []
      };

      await setDoc(docRef, data);
      sessionStorage.setItem('companyChosen', formData.company);
      props.onSubmit();
    } catch (err) {
      console.error("Error adding document: ", err);
    }
  }

  function handleSelectChange(e) {
    const selectedCompany = e.target.value;
    setFormDataSelect({ company: selectedCompany });
    handleSubmitSelect(selectedCompany);
  }

  async function handleSubmitSelect(selectedCompany) {
    try {
      sessionStorage.setItem('companyChosenVSM', selectedCompany);
      console.log("Successfully selected company: ", selectedCompany);
      props.onSubmit();
    } catch(err) {
      console.error("Error selecting company: ", err);
    }
  }

  return (
    <div className={styles.page}>
      <h2>Choose an Existing Company</h2>
      {loading ? (
        <p>Loading companies...</p>
      ) : (
        <>
          <form className={styles.form} tabIndex={0}>
            <label className={styles.label}>
              <select
                name="companySelect"
                value={formDataSelect.company}
                onChange={handleSelectChange}
                className={styles.select}
              >
                <option value="" disabled>Select a company</option>
                {companies.map((company, index) => (
                  <option key={index} value={company}>{company}</option>
                ))}
              </select>
            </label>
          </form>
        </>
      )}
    </div>
  );
}
