import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { NavLink } from 'react-router-dom';
import { BarChart } from '@mui/icons-material';
import styles from "./DashboardVSM.module.css";

import { useFieldArray,  } from 'react-hook-form'
import { useSupplierContext } from '../contexts/SupplierContext';
import { useEffect } from 'react';
import { useAllSupplierContext } from '../contexts/SupHandlerContext';
import { useCustomerContext } from '../contexts/CustomerContext';

import { useProcessContext } from '../contexts/ProcessContext';
import { useAllInventoryContext } from '../contexts/InventoryContext';

export default function VSMInventory() {

  const {inventories, updateInventory} = useAllInventoryContext();
  const { numberOfProcess } = useProcessContext();
  const { register, formState, handleSubmit,setValue} = useForm({
    defaultValues:{
      inventories: [{ processINumber: null }]
    }
  });
  const navigate = useNavigate(); // Instancia o hook useNavigate
  const { errors } = formState;

  useEffect(()=>{
    setValue('inventories', inventories)
  }, [inventories, setValue] );


  const renderInventory = () => {
    const inventories = [];
    for (let j = 0; j < numberOfProcess; j++) {
      inventories.push(
        <div key={`inventory${j}`} id={`inventory${j}`} className="inventory">
          <br />
          <label htmlFor={`inventories.${j}.processINumber`}>Process {j + 1} Inventory:</label>
          <input type="number"
            {...register(`inventories.${j}.processINumber`, {
              required: {
                value: true,
                message: "Inventory is Required, and MUST receive only numbers",
              },
              validate: value => {
                if (value != null) {
                  if (value < 0) {
                    return 'Inventory must be positive!'
                  }
                  if (isNaN(Number(value))) {
                    return 'Please enter a valid number!';
                  }
                  return true;
                }
              }
            })} />
          <p className='errorsValidation'>{errors?.inventories?.[j]?.processINumber?.message}</p>
        </div>
      );
      if (j < numberOfProcess - 1) {
        inventories.push(
          <div key={`divisionLine${j}`} className='divisionLine'></div>
        )
      }
    }
    return inventories;
  };

  const onSubmit = async (data) => {
    try {
      for(let i=0; i< numberOfProcess; i++){
        const updatedInventory = {
          processINumber: data.inventories[i].processINumber
        };
        updateInventory(i, updatedInventory);
        console.log("Inventory: ", updatedInventory, " Salvo na posição ", i)
      }
      navigate("/matflowVSM")
    } catch (error) {
      
    }
  }

  const handlePrevious = () => {
    navigate('/processVSM')
  } 

  return (
    <div>
      <div className={styles.header}>
      <Button variant="outlined" startIcon={<BarChart />} href="/otherMapsVSM">Other Maps</Button>

        <div className={styles.tabContainer}>
        <ul>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Map Infos</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Supplier</Button></li>
          <li><Button style={{ backgroundColor: 'rgb(0, 99, 228)', color: 'whitesmoke' }}>Customer</Button></li>
          <li><Button>Process Creation</Button></li>
          <li><Button>Inventory</Button></li>
          <li><Button>Material Flow Data</Button></li>
          <li><Button>Informational Flow Data</Button></li>
        </ul>
      </div>
      </div>

        <div className='tab'>
          <form id="inventoryForm" onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off" noValidate>
          <div className="buttons-div">
                        <div className='previousButton'>
                            <button type="button" onClick={handlePrevious}>Previous</button>
                        </div>
                        <div className="flex-container">
                            <button type="submit">Submit / Next</button>
                        </div>
                        </div>
            <br /><br />
            <h2>Inventory</h2>
            <div className='inventory'>
              {renderInventory()}
            </div>
          </form>
        </div>
    </div>
  );
}
