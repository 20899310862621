// import {useState, useEffect, useRef} from 'react';

import 'reactjs-popup/dist/index.css';
import { auth } from '../firebase_setup/firebase';
import styles from "./Dashboard.module.css"
import { collection, getDocs, query } from "firebase/firestore";
import {db} from '../firebase_setup/firebase';
import { useState, useEffect } from 'react';
import { getFirestore, addDoc, doc } from "firebase/firestore";



const firestore = getFirestore();




export default function DashboardMembers() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Reference to the "userData" subcollection for the current user
        const userDataCollectionRef = collection(getFirestore(), 'userRole', auth.currentUser.uid, 'roles');

        // Fetch documents from the "userData" subcollection
        const userDataQuery = query(userDataCollectionRef);
        const userDataSnapshot = await getDocs(userDataQuery);

        // Check if the subcollection has any documents
        if (!userDataSnapshot.empty) {
          // Subcollection exists, extract and set data
          const data = userDataSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setUserData(data);
        } else {
          // Subcollection does not exist or is empty
          console.log('The "userData" subcollection does not exist or is empty.');
          console.log(userData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    // Call the function to fetch user data on component mount
    fetchUserData();
  }, []); // Empty dependency array ensures the effect runs once on mount

  console.log(userData); 

  /*
  const [todos, setTodos] = useState([]);
  const fetchPost = async () => {
       
   await getDocs(collection(db, "userRole"))
      .then((querySnapshot)=>{               
          const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
          setTodos(newData);                
          console.log(todos, newData);
      })
 
  }

  useEffect(()=>{
    fetchPost();
  }, []) */

  /*
  useEffect(()=>{
  const aref = getFirestore().collection("userRole").doc(auth.currentUser.uid).get().then((snapshot) => {
    console.log(snapshot.data())
  }).catch((e) => console.log(e))
  }, [])
*/

  //sessionStorage.setItem('type', 'Member')
  //console.log(sessionStorage)
  
  return (
    <div>
      <div className={styles.header}>
        <h2>Welcome, {auth.currentUser.displayName}.</h2>
        <h2>Here will be other tools.</h2>
      </div>
    </div>
  )
} 

/*
// import {useState, useEffect, useRef} from 'react';
import 'reactjs-popup/dist/index.css';
import { auth } from '../firebase_setup/firebase';
import styles from "./Dashboard.module.css"
import { useNavigate } from 'react-router-dom';


export default function DashboardMembers() {
  const navigate = useNavigate();
  //sessionStorage.setItem('type', 'Member')
  //console.log(sessionStorage)
  if (!auth.currentUser) {
    // Redirect to login or handle the case where there is no user
    navigate('/');
    return null;
  }
  return (
    <div>
      <div className={styles.header}>
        <h2>Welcome, {auth.currentUser.displayName}.</h2>
        <h2>Here will be other tools.</h2>
      </div>
    </div>
  )
} */