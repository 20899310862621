import styles from "./page.03.module.css"
import Nav from "../components/Nav"
import { Link } from "react-router-dom"


export default function Signup() {


  return (
    <div className={styles.landing}>
      <Nav/>

      <div className={styles.bodycontent}>
      <div className={styles.logo_intro}>
        <img src={require("../assets/PiTechLogo2E2.jpg")} alt="PiTech Academy"></img>
      </div>
        <div className={styles.textcontent}>
        <h1 style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Members Login</h1>

        <p style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Login for members. <br></br> Contact us for member sign up. </p>
        <Link className={styles.register} to="/loginMembers" style={{
        fontFamily:"'Titillium Web', sans-serif",
        marginLeft: "0%"
      }}>Join Now</Link>
      </div>
      
      </div>


      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
    </div>
  )
}

