// import { useState, useEffect } from 'react';
// import 'reactjs-popup/dist/index.css';
// import styles from "../../pages/Dashboard5S.module.css";
// import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import { NavLink } from "react-router-dom";
// import Button from '@mui/material/Button';
// import { BarChart } from "@mui/icons-material";
// import { doc, getDoc } from "firebase/firestore";
// import { auth, db } from "../../firebase_setup/firebase";
// import Sort from "./forms/Sort";
// import SetInOrder from "./forms/SetInOrder";
// import Shine from "./forms/Shine";
// import Standardise from "./forms/Standardise";
// import Sustain from "./forms/Sustain";
// import Details from "./forms/Details";
// import { Stepper, StepLabel, Step, Grid } from "@mui/material";
// import { useContext } from "react";
// import { MultiStepContext } from "./StepContext";



// const steps = [
//   {
//     label: "Details",
//   },
//   {
//     label: "Sort",
//   },
//   {
//     label: "Set In Order",
//   },
//   {
//     label: "Shine",
//   },
//   {
//     label: "Standardise",
//   },
//   {
//     label: "Sustain",
//   },
// ];

// /*
// const NewAudit = ({ companyID }) => {
//   // Access context for current step
//   const { currentStep } = useContext(MultiStepContext);

//   // Function to render the content based on the current step
//   function showStep(step) {
//     switch (step) {
//       case 0:
//         return <Details companyID={companyID}></Details>;
//       case 1:
//         return <Sort></Sort>;
//       case 2:
//         return <SetInOrder></SetInOrder>;
//       case 3:
//         return <Shine></Shine>;
//       case 4:
//         return <Standardise></Standardise>;
//       case 5:
//         return <Sustain></Sustain>;
//       default:
//         return null;
//     }
//   }

//   return (
//     <>
//       <Grid container sx={{ padding: "5rem 0rem 2rem" }}> */
//         {/* Left section for vertical Stepper (visible on medium screens and larger) */} /*
//         <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
//           <Stepper activeStep={currentStep} orientation="vertical">
//             {steps.map((step) => (
//               <Step key={step.label}>
//                 <StepLabel>{step.label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//         </Grid>
//         */
//         {/* Middle section for horizontal Stepper (visible on small screens) */} /*
//         <Grid
//           item
//           md={4}
//           sx={{
//             display: { xs: "flex", md: "none" },
//             padding: "0rem 0rem 2rem",
//             marginRight: "auto",
//             marginLeft: "auto",
//           }}
//         >
//           <Stepper activeStep={currentStep} orientation="horizontal">
//             {steps.map((step) => (
//               <Step key={step.label}>
//                 <StepLabel></StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//         </Grid>
//         */
//         {/* Section for form content */} /*
//         <Grid item md={8} xs={12}>
//           <>{showStep(currentStep)}</>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default NewAudit;
// */

// const settingsUser = [
//   { title: "New Audit", path: "/newAudit5S" },
//   { title: "Setting", path: "/5Ssettings" },
// ];

// export default function NewAudit() {
//   const [companyID, setCompany] = useState({});
//   const [supervisors, setSupervisors] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [anchorElUser, setAnchorElUser] = useState(null);
//   const { currentStep } = useContext(MultiStepContext);

//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const companyName = sessionStorage.getItem('companyChosen');
//         const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
//         const docSnap = await getDoc(docRef);
    
//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           // Extract audits, auditAreas, and supervisors from the data
//           const { auditAreas, supervisors, ...otherData } = data;
          
//           setCompany(otherData); // Set the rest of the data as company
//           setSupervisors(supervisors); // Set supervisors, default to empty array if null
//           setAreas(auditAreas); // Set auditAreas, default to empty array if null
          
//           // Here, audits, auditAreas, and supervisors will be arrays if they exist
//           console.log("Audit Areas:", auditAreas);
//           console.log("Supervisors:", supervisors);
//         } else {
//           console.log("No such document!");
//         }
//       } catch (err) {
//         console.error("Error getting document:", err);
//       }
//     };
  
//     fetchData();
//   }, []);

//   return (
//     <>
//       <div className={styles.header}>
//         <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>
//         <Box>
//           <Tooltip title="Open Settings">
//             <IconButton onClick={handleOpenUserMenu}>
//               <PersonIcon />
//             </IconButton>
//           </Tooltip>
//           <Menu
//             sx={{ mt: "50px" }}
//             anchorEl={anchorElUser}
//             anchorOrigin={{ vertical: "top", horizontal: "right" }}
//             keepMounted
//             transformOrigin={{ vertical: "top", horizontal: "right" }}
//             open={Boolean(anchorElUser)}
//             onClose={handleCloseUserMenu}
//           >
//             {settingsUser.map((item) => (
//               <MenuItem
//                 component={NavLink}
//                 to={item.path}
//                 key={item.title}
//                 onClick={handleCloseUserMenu}
//               >
//                 <Typography textAlign="center">{item.title}</Typography>
//               </MenuItem>
//             ))}
//           </Menu>
//         </Box>
//       </div>
      
//     </>
//   );
// }
import { useState, useEffect, useContext } from 'react';
import 'reactjs-popup/dist/index.css';
import styles from "../../pages/Dashboard5S.module.css";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Stepper, StepLabel, Step, Grid, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import { BarChart } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase_setup/firebase";
import Sort from "./forms/Sort";
import SetInOrder from "./forms/SetInOrder";
import Shine from "./forms/Shine";
import Standardise from "./forms/Standardise";
import Sustain from "./forms/Sustain";
import Details from "./forms/Details";
import { MultiStepContext } from "./StepContext";

const steps = [
  { label: "Details" },
  { label: "Sort" },
  { label: "Set In Order" },
  { label: "Shine" },
  { label: "Standardise" },
  { label: "Sustain" },
];

const NewAudit = () => {
  const [companyID, setCompany] = useState({});
  const [supervisors, setSupervisors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { currentStep } = useContext(MultiStepContext);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const { auditAreas, supervisors, ...otherData } = data;
          setCompany(otherData);
          setSupervisors(supervisors || []);
          setAreas(auditAreas || []);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };

    fetchData();
  }, []);

  const showStep = (step) => {
    switch (step) {
      case 0: return <Details companyID={companyID} />;
      case 1: return <Sort />;
      case 2: return <SetInOrder />;
      case 3: return <Shine />;
      case 4: return <Standardise />;
      case 5: return <Sustain />;
      default: return null;
    }
  };

  if (currentStep === null || currentStep === undefined) {
    // Handle the case where currentStep is not yet initialized
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={styles.header}>
        <Button variant="outlined" startIcon={<BarChart />} href="/5Sstats">Statistics</Button>
        <Box>
          <Tooltip title="Open Settings">
            <IconButton onClick={handleOpenUserMenu}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "50px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settingsUser.map((item) => (
              <MenuItem
                component={NavLink}
                to={item.path}
                key={item.title}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
      <Grid container sx={{ padding: "5rem 0rem 2rem", marginLeft: "3%", marginRight: "3%", width: "94%" }}>
        <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
          <Stepper activeStep={currentStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: { xs: "flex", md: "none" },
            padding: "0rem 0rem 2rem",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Stepper activeStep={currentStep} orientation="horizontal">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item md={8} xs={12}>
          {showStep(currentStep)}
        </Grid>
      </Grid>
    </>
  );
};

const settingsUser = [
  { title: "New Audit", path: "/newAudit5S" },
  { title: "Setting", path: "/5Ssettings" },
];

export default NewAudit;
