// import React, { useState, ReactNode } from "react";
// import dayjs from "dayjs";
// import { useNavigate } from "react-router-dom";

// export const MultiStepContext = React.createContext(null);

// const StepContext = ({ children, companyID }) => {
//   const navigate = useNavigate();
//   const [currentStep, setStep] = useState(0);
//   const [userData, setUserData] = useState({
//     area_audit: "",
//     supervisor: "",
//     date: dayjs(),
//     // Answers to the audit questions, initialized to 1
//     question01: 1,
//     question02: 1,
//     question03: 1,
//     question04: 1,
//     question05: 1,
//     question06: 1,
//     question07: 1,
//     question08: 1,
//     question09: 1,
//     question10: 1,
//     question11: 1,
//     question12: 1,
//     question13: 1,
//     question14: 1,
//     question15: 1,
//     question16: 1,
//     question17: 1,
//     question18: 1,
//     question19: 1,
//     question20: 1,
//     question21: 1,
//     question22: 1,
//     question23: 1,
//     question24: 1,
//     question25: 1,
//   });


//   // Function to submit the audit data to the server
//   function submitData() {
//     let supervisorID, areaAuditID, sortID, setID, shineID, standardizeID, sustainID;

//     // Fetch the supervisors of the company
//     fetch(`http://localhost:4000/companies/${companyID}/supervisors`)
//       .then(response => response.json())
//       .then(data => {
//         supervisorID = data.find(supervisor => supervisor.name === userData.supervisor).id;

//         // Fetch the audit areas of the company
//         return fetch(`http://localhost:4000/companies/${companyID}/areasAudit`)
//           .then(response => response.json())
//           .then(data => {
//             areaAuditID = data.find(areaAudit => areaAudit.name === userData.area_audit).id;

//             // Submit the answers for the Sort section
//             return fetch("http://localhost:4000/sortAnswers", {
//               method: "POST",
//               body: JSON.stringify({
//                 answer1: userData.question01,
//                 answer2: userData.question02,
//                 answer3: userData.question03,
//                 answer4: userData.question04,
//                 answer5: userData.question05,
//               }),
//               headers: {
//                 "Content-Type": "application/json",
//               },
//             })
//               .then(response => response.json())
//               .then(data => {
//                 sortID = data.id;

//                 // Submit the answers for the Set section
//                 return fetch("http://localhost:4000/setAnswers", {
//                   method: "POST",
//                   body: JSON.stringify({
//                     answer6: userData.question06,
//                     answer7: userData.question07,
//                     answer8: userData.question08,
//                     answer9: userData.question09,
//                     answer10: userData.question10,
//                   }),
//                   headers: {
//                     "Content-Type": "application/json",
//                   },
//                 })
//                   .then(response => response.json())
//                   .then(data => {
//                     setID = data.id;

//                     // Submit the answers for the Shine section
//                     return fetch("http://localhost:4000/shineAnswers", {
//                       method: "POST",
//                       body: JSON.stringify({
//                         answer11: userData.question11,
//                         answer12: userData.question12,
//                         answer13: userData.question13,
//                         answer14: userData.question14,
//                         answer15: userData.question15,
//                       }),
//                       headers: {
//                         "Content-Type": "application/json",
//                       },
//                     })
//                       .then(response => response.json())
//                       .then(data => {
//                         shineID = data.id;

//                         // Submit the answers for the Standardize section
//                         return fetch("http://localhost:4000/standardizeAnswers", {
//                           method: "POST",
//                           body: JSON.stringify({
//                             answer16: userData.question16,
//                             answer17: userData.question17,
//                             answer18: userData.question18,
//                             answer19: userData.question19,
//                             answer20: userData.question20,
//                           }),
//                           headers: {
//                             "Content-Type": "application/json",
//                           },
//                         })
//                           .then(response => response.json())
//                           .then(data => {
//                             standardizeID = data.id;

//                             // Submit the answers for the Sustain section
//                             return fetch("http://localhost:4000/sustainAnswers", {
//                               method: "POST",
//                               body: JSON.stringify({
//                                 answer21: userData.question21,
//                                 answer22: userData.question22,
//                                 answer23: userData.question23,
//                                 answer24: userData.question24,
//                                 answer25: userData.question25,
//                               }),
//                               headers: {
//                                 "Content-Type": "application/json",
//                               },
//                             })
//                               .then(response => response.json())
//                               .then(data => {
//                                 sustainID = data.id;

//                                 // Submit the final audit data
//                                 return fetch("http://localhost:4000/audits", {
//                                   method: "POST",
//                                   body: JSON.stringify({
//                                     date: userData.date,
//                                     sort_id: sortID,
//                                     setinorder_id: setID,
//                                     shine_id: shineID,
//                                     standardize_id: standardizeID,
//                                     sustain_id: sustainID,
//                                     supervisor_id: supervisorID,
//                                     area_id: areaAuditID,
//                                     company_id: companyID,
//                                   }),
//                                   headers: {
//                                     "Content-Type": "application/json",
//                                   },
//                                 })
//                                   .then(response => response.json())
//                                   .then(response => {
//                                     console.log(response);

//                                     // Reset the user data and the current step
//                                     setUserData({
//                                       area_audit: "",
//                                       supervisor: "",
//                                       date: dayjs(),
//                                       question01: 1,
//                                       question02: 1,
//                                       question03: 1,
//                                       question04: 1,
//                                       question05: 1,
//                                       question06: 1,
//                                       question07: 1,
//                                       question08: 1,
//                                       question09: 1,
//                                       question10: 1,
//                                       question11: 1,
//                                       question12: 1,
//                                       question13: 1,
//                                       question14: 1,
//                                       question15: 1,
//                                       question16: 1,
//                                       question17: 1,
//                                       question18: 1,
//                                       question19: 1,
//                                       question20: 1,
//                                       question21: 1,
//                                       question22: 1,
//                                       question23: 1,
//                                       question24: 1,
//                                       question25: 1,
//                                     });
//                                     navigate("/statistics");
//                                     setStep(0);
//                                   });
//                               });
//                           });
//                       });
//                   });
//               });
//           });
//       });
//   }

//   return (
//     <MultiStepContext.Provider
//       value={{
//         currentStep,
//         setStep,
//         userData,
//         setUserData,
//         submitData,
//       }}
//     >
//       {children}
//     </MultiStepContext.Provider>
//   );
// };

// export default StepContext;






// import React, { useState } from "react";
// import dayjs from "dayjs";
// import { useNavigate } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import { auth, db } from "../../firebase_setup/firebase";

// export const MultiStepContext = React.createContext(null);

// const StepContext = ({ children, companyID }) => {
//   //const [companyID, setCompany] = useState({});
//   const [supervisors, setSupervisors] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const navigate = useNavigate();
//   const [currentStep, setStep] = useState(0);
//   const [userData, setUserData] = useState({
//     area_audit: "",
//     supervisor: "",
//     date: dayjs(),
//     question01: 1,
//     question02: 1,
//     question03: 1,
//     question04: 1,
//     question05: 1,
//     question06: 1,
//     question07: 1,
//     question08: 1,
//     question09: 1,
//     question10: 1,
//     question11: 1,
//     question12: 1,
//     question13: 1,
//     question14: 1,
//     question15: 1,
//     question16: 1,
//     question17: 1,
//     question18: 1,
//     question19: 1,
//     question20: 1,
//     question21: 1,
//     question22: 1,
//     question23: 1,
//     question24: 1,
//     question25: 1,
//   });

//   const submitData = async () => {
//     try {
//       try {
//         const companyName = sessionStorage.getItem('companyChosen');
//         const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           const { auditAreas, supervisors, ...otherData } = data;
//           //setCompany(otherData);
//           setSupervisors(supervisors || []);
//           setAreas(auditAreas || []);
//         } else {
//           console.log("No such document!");
//         }
//       } catch (err) {
//         console.error("Error getting document:", err);
//       }
//       // Fetch supervisors
//       //const supervisorResponse = await fetch(`http://localhost:4000/companies/${companyID}/supervisors`);
//       //const supervisors = supervisors;
//       const supervisorID = supervisors.find(supervisor => supervisor.name === userData.supervisor)?.id;

//       // Fetch audit areas
//      // const areaResponse = await fetch(`http://localhost:4000/companies/${companyID}/areasAudit`);
//       //const areas = await areaResponse.json();
//       const areaAuditID = areas.find(areaAudit => areaAudit.name === userData.area_audit)?.id;

//       // Submit answers for Sort section
//       const sortResponse = await fetch("http://localhost:4000/sortAnswers", {
//         method: "POST",
//         body: JSON.stringify({
//           answer1: userData.question01,
//           answer2: userData.question02,
//           answer3: userData.question03,
//           answer4: userData.question04,
//           answer5: userData.question05,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const sortID = (await sortResponse.json()).id;

//       // Submit answers for Set section
//       const setResponse = await fetch("http://localhost:4000/setAnswers", {
//         method: "POST",
//         body: JSON.stringify({
//           answer6: userData.question06,
//           answer7: userData.question07,
//           answer8: userData.question08,
//           answer9: userData.question09,
//           answer10: userData.question10,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const setID = (await setResponse.json()).id;

//       // Submit answers for Shine section
//       const shineResponse = await fetch("http://localhost:4000/shineAnswers", {
//         method: "POST",
//         body: JSON.stringify({
//           answer11: userData.question11,
//           answer12: userData.question12,
//           answer13: userData.question13,
//           answer14: userData.question14,
//           answer15: userData.question15,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const shineID = (await shineResponse.json()).id;

//       // Submit answers for Standardize section
//       const standardizeResponse = await fetch("http://localhost:4000/standardizeAnswers", {
//         method: "POST",
//         body: JSON.stringify({
//           answer16: userData.question16,
//           answer17: userData.question17,
//           answer18: userData.question18,
//           answer19: userData.question19,
//           answer20: userData.question20,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const standardizeID = (await standardizeResponse.json()).id;

//       // Submit answers for Sustain section
//       const sustainResponse = await fetch("http://localhost:4000/sustainAnswers", {
//         method: "POST",
//         body: JSON.stringify({
//           answer21: userData.question21,
//           answer22: userData.question22,
//           answer23: userData.question23,
//           answer24: userData.question24,
//           answer25: userData.question25,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const sustainID = (await sustainResponse.json()).id;

//       // Submit final audit data
//       const auditResponse = await fetch("http://localhost:4000/audits", {
//         method: "POST",
//         body: JSON.stringify({
//           date: userData.date,
//           sort_id: sortID,
//           setinorder_id: setID,
//           shine_id: shineID,
//           standardize_id: standardizeID,
//           sustain_id: sustainID,
//           supervisor_id: supervisorID,
//           area_id: areaAuditID,
//           company_id: companyID,
//         }),
//         headers: { "Content-Type": "application/json" },
//       });
//       const auditResult = await auditResponse.json();
//       console.log("Audit submission result:", auditResult);

//       // Reset user data and navigate to statistics page
//       setUserData({
//         area_audit: "",
//         supervisor: "",
//         date: dayjs(),
//         question01: 1,
//         question02: 1,
//         question03: 1,
//         question04: 1,
//         question05: 1,
//         question06: 1,
//         question07: 1,
//         question08: 1,
//         question09: 1,
//         question10: 1,
//         question11: 1,
//         question12: 1,
//         question13: 1,
//         question14: 1,
//         question15: 1,
//         question16: 1,
//         question17: 1,
//         question18: 1,
//         question19: 1,
//         question20: 1,
//         question21: 1,
//         question22: 1,
//         question23: 1,
//         question24: 1,
//         question25: 1,
//       });
//       setStep(0);
//       navigate("/statistics");
//     } catch (error) {
//       console.error("Error submitting audit data:", error);
//     }
//   };

//   return (
//     <MultiStepContext.Provider
//       value={{
//         currentStep,
//         setStep,
//         userData,
//         setUserData,
//         submitData,
//       }}
//     >
//       {children}
//     </MultiStepContext.Provider>
//   );
// };

// export default StepContext;


import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { auth, db } from "../../firebase_setup/firebase";

/*export const MultiStepContext = React.createContext({
  currentStep: 0,
  setStep: () => {}, // Provide default functions if needed
  userData: {},
  setUserData: () => {},
  submitData: () => {},
}); */

export const MultiStepContext = React.createContext(null);

const StepContext = ({ children, companyID }) => {
  const [supervisors, setSupervisors] = useState([]);
  const [areas, setAreas] = useState([]);
  const navigate = useNavigate();
  const [currentStep, setStep] = useState(0);
  const [userData, setUserData] = useState({
    area_audit: "",
    supervisor: "",
    date: dayjs(),
    question01: 1,
    question02: 1,
    question03: 1,
    question04: 1,
    question05: 1,
    question06: 1,
    question07: 1,
    question08: 1,
    question09: 1,
    question10: 1,
    question11: 1,
    question12: 1,
    question13: 1,
    question14: 1,
    question15: 1,
    question16: 1,
    question17: 1,
    question18: 1,
    question19: 1,
    question20: 1,
    question21: 1,
    question22: 1,
    question23: 1,
    question24: 1,
    question25: 1,
  });

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyName = sessionStorage.getItem('companyChosen');
        const docRef = doc(db, "users", auth.currentUser.uid, "5SCompanies", companyName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const { auditAreas, supervisors } = data;
          console.log("Document data:", data);
          setSupervisors(supervisors || []);
          setAreas(auditAreas || []);
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };

    fetchCompanyData();
  }, []);

  const submitData = async () => {
    try {
      const supervisorID = supervisors.find(supervisor => supervisor === userData.supervisor);
      console.log("Supervisor ID:", supervisorID);
      console.log("Supervisors Array:", supervisors);
      console.log("User Data Supervisor:", userData.supervisor);

      const areaAuditID = areas.find(areaAudit => areaAudit === userData.area_audit);
      const companyName = sessionStorage.getItem('companyChosen');

      // Submit answers for Sort section
      const sortRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "sortAnswers");
      const sortDocRef = await addDoc(sortRef, {
        answer1: userData.question01,
        answer2: userData.question02,
        answer3: userData.question03,
        answer4: userData.question04,
        answer5: userData.question05,
      });
      const sortID = sortDocRef.id;

      // Submit answers for Set section
      const setRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "setAnswers");
      const setDocRef = await addDoc(setRef, {
        answer6: userData.question06,
        answer7: userData.question07,
        answer8: userData.question08,
        answer9: userData.question09,
        answer10: userData.question10,
      });
      const setID = setDocRef.id;

      // Submit answers for Shine section
      const shineRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "shineAnswers");
      const shineDocRef = await addDoc(shineRef, {
        answer11: userData.question11,
        answer12: userData.question12,
        answer13: userData.question13,
        answer14: userData.question14,
        answer15: userData.question15,
      });
      const shineID = shineDocRef.id;

      // Submit answers for Standardize section
      const standardizeRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "standardizeAnswers");
      const standardizeDocRef = await addDoc(standardizeRef, {
        answer16: userData.question16,
        answer17: userData.question17,
        answer18: userData.question18,
        answer19: userData.question19,
        answer20: userData.question20,
      });
      const standardizeID = standardizeDocRef.id;

      // Submit answers for Sustain section
      const sustainRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "sustainAnswers");
      const sustainDocRef = await addDoc(sustainRef, {
        answer21: userData.question21,
        answer22: userData.question22,
        answer23: userData.question23,
        answer24: userData.question24,
        answer25: userData.question25,
      });
      const sustainID = sustainDocRef.id;

      // Submit final audit data
      const auditRef = collection(db, "users", auth.currentUser.uid, "5SCompanies", companyName, "audits");
      const auditDocRef = await addDoc(auditRef, {
        date: userData.date.toISOString(),
        sort_id: sortID,
        setinorder_id: setID,
        shine_id: shineID,
        standardize_id: standardizeID,
        sustain_id: sustainID,
        supervisor_id: supervisorID,
        area_id: areaAuditID,
        company_id: companyID,
      });
      console.log("Audit submission result:", auditDocRef);

      // Reset user data and navigate to statistics page
      setUserData({
        area_audit: "",
        supervisor: "",
        date: dayjs(),
        question01: 1,
        question02: 1,
        question03: 1,
        question04: 1,
        question05: 1,
        question06: 1,
        question07: 1,
        question08: 1,
        question09: 1,
        question10: 1,
        question11: 1,
        question12: 1,
        question13: 1,
        question14: 1,
        question15: 1,
        question16: 1,
        question17: 1,
        question18: 1,
        question19: 1,
        question20: 1,
        question21: 1,
        question22: 1,
        question23: 1,
        question24: 1,
        question25: 1,
      });
      setStep(0);
      navigate("/5Sstats");
    } catch (error) {
      console.error("Error submitting audit data:", error);
    }
  };

  return (
    <MultiStepContext.Provider
      value={{
        currentStep,
        setStep,
        userData,
        setUserData,
        submitData,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
};

export default StepContext;
