import { useState } from 'react';
import { auth, db } from "../firebase_setup/firebase";
import { doc, setDoc } from "firebase/firestore";
import industries from "../data/industries.json";
import styles from "./SiteForm.module.css"

export default function SiteForm(props) {
  const [errorMesage, setErrorMessage] = useState("")
  const [formData, setFormData] = useState({
    site: "",
    company: "",
    location: {
      city: "",
      province: "",
      country: ""
    },
    employeeRange: "",
    completed: false
  })

  function handleChange(e) {
    setErrorMessage("")
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    })
  }

  function handleLocationChange(e) {
    setErrorMessage("")
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        location: {
          ...prevFormData.location,
          [e.target.name]: e.target.value
        }
      }
    })
  }

  // Checks if object contains any empty strings / null values
  function hasEmpty(obj) {
    for(var key in obj) {
      if(obj[key] === null || obj[key] === undefined) return true;
      if((typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) return true;
      if((typeof obj[key] === 'string' && obj[key].trim().length === 0)) return true;
      if(obj[key] instanceof Object) return hasEmpty(obj[key]);
    }
    
    return false;
  }

  async function handleSubmit() {
    // Validate new site ID and proper formData before submitting
    if (props.siteIDs.includes(formData.site)){
      setErrorMessage("Invalid site name, already in use. Please use another name.")
    } else if (hasEmpty(formData)) {
      setErrorMessage("Invalid form input(s). Please ensure all fields are completed.")
    } else {
      try {
        await setDoc(doc(db, "users", auth.currentUser.uid, "sites", formData.site), formData);
        props.onSubmit()
      } catch(err) {
        console.error("Error adding document: ", err)
      }
    }
  }

  return (
    <div className={styles.page}>
      <h2>Add New Site for Self-Assessment</h2>
      <p style={{color:"red"}}>{errorMesage}</p>
      <form className={styles.form} tabIndex={0}>
        <label className={styles.label}>
          <span>Site Name</span>
          <input
            name="site"
            value={formData.site}
            onChange={handleChange}
          />
        </label>

        <label className={styles.label}>
          <span>Company</span>
          <input
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </label>

        <label className={styles.label}>
          <span>Industry</span>
          <select
            name="industry"
            value={formData.industry}
            onChange={handleChange}
          >
            <option value={null}>Select</option>
            {industries.map((title, idx) => {
              return <option key={idx} value={title}>{title}</option>
            })}
          </select>
        </label>

        <fieldset className={styles.location}>
            <legend>Site Location</legend>

            <label className={styles.label}>
              <span>City</span>
              <input
                name="city"
                value={formData.location.city}
                onChange={handleLocationChange}
              />
            </label>
            
            <label className={styles.label}>
              <span>Province</span>
              <input
                name="province"
                value={formData.location.province}
                onChange={handleLocationChange}
              />
            </label>
            
            <label className={styles.label}>
              <span>Country</span>
              <input
                name="country"
                value={formData.location.country}
                onChange={handleLocationChange}
              />
            </label>
        </fieldset>
        
        <label className={styles.label}>
          <span>Number of Employees at Site</span>
          <select
            name="employeeRange"
            value={formData.employeeRange}
            onChange={handleChange}
          >
            <option value={null}>Select</option>
            <option value="Less than 10">Less than 10</option>
            <option value="10-49">10-49</option>
            <option value="50-99">50-99</option>
            <option value="100-249">100-249</option>
            <option value="250-499">250-499</option>
            <option value="500+">500+</option>
          </select>
        </label>
    </form>
    <button className={styles.submit} onClick={handleSubmit}>SUBMIT</button>
  </div>
  )
}