import React, { useState, useLayoutEffect, useRef } from "react"
import styles from "./DonutChart.module.css"

export default function DonutChart(props) {
  const [size, setSize] = useState([999, 999])
  const ref = useRef(null)

  useLayoutEffect(() => {
    function updateSize() {
      setSize([ref.current.clientWidth, ref.current.clientHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const emptyColor = "lightgray"
  const colorScale = ["#FF0D0D", "#FF4E11", "#FF8E15", "#FAB733", "#96B34C", "#04A722"]
  
  const labels = Object.keys(props.labelledMeans)
  const sectionIncrement = 360 / labels.length
  const sectionOffset = -sectionIncrement / 2

  const diameter = Math.min(...size) / 2
  const centerDiameter = diameter / 3
  const levelStep = 0.375 * centerDiameter
  const levelWidths = new Array(props.levels-1)
                  .fill(centerDiameter)
                  .map((x,i) => x + (i+1) * levelStep)
  
  return (
    <div ref={ref} style={{
      position:"relative",
      width:"100%",
      maxWidth: "800px",
      height: `${size[0]-175}px`,
      minHeight: "300px"
    }}>
      <div style={{position:"absolute", inset: 0}}>
        {/* Background Circle coloured via emptyColor */}
        <div style={{
          position: "absolute",
          margin: "auto",
          inset: 0,
          backgroundColor: emptyColor,
          width: `${levelWidths[levelWidths.length-1]}px`,
          height: `${levelWidths[levelWidths.length-1]}px`,
          borderRadius: "50%",
          border: "2px solid black",
        }}></div>

        {/* Donut sections colored/filled depending on each score */}
        {labels.map((label, i) => {
          let color = "transparent", width = 0
          for (let level = 0; level < props.levels; level++) {
            if (props.labelledMeans[label] >= level) {
              color = colorScale[Math.round(props.labelledMeans[label])]
              width = centerDiameter + props.labelledMeans[label] * levelStep
            }
          }
          return <div key={i} className={styles.pie} 
            style={{
              transform: `rotate(${sectionOffset + i*sectionIncrement}deg)`,
              "--c": color,
              "--w": `${width}px`,
              "--p": 100 / labels.length
            }}
          ></div>
        })}
        
        {/* Black circles outlining levels within each seciton */}
        {levelWidths.map((w,i) => <div key={i} style={{
          width:`${w}px`, 
          height:`${w}px`, 
          borderRadius:"50%",
          border: "2px solid black",
          position:"absolute",
          margin:"auto",
          inset:0,
        }}></div>)}

        {/* Black outlines dividing sections */}
        {labels.map((_,i) => <div key ={i} style={{
          width:"0",
          border: "1px solid black",
          height:`${levelWidths[levelWidths.length-1]}px`,
          position:"absolute",
          margin:"auto",
          inset:0,
          transform: `rotate(${sectionOffset + i*sectionIncrement}deg)`,
          clipPath: `inset(0 0 ${levelWidths[levelWidths.length-1]/2}px 0)`
        }}></div>)}

        {/* Center of chart, displaying overall score */}
        <div style={{
          position:"absolute",
          margin:"auto",
          inset:0,
          backgroundColor:"white",
          width:`${centerDiameter}px`,
          height:`${centerDiameter}px`,
          borderRadius:"50%",
          border: "2px solid black",
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center"
        }}>
          <span style={{
            color: colorScale[Math.round(props.overall)],
            fontWeight: "bold",
            fontSize: `${centerDiameter/4}px`,
            margin: 0
          }}>
            {props.overall}
          </span>
          <span style={{
            fontWeight: "bold",
            fontSize: `${centerDiameter/8}px`,
          }}>
            OVERALL
          </span>
        </div>

        {/* Labels for each section, outside of donut */}
        {labels.map((label, i) => <div
          key={i}
          style={{
            backgroundColor:"#f2f2f2",
            padding:`${centerDiameter/16}px`,
            border: "1px solid black",
            borderRadius:`${centerDiameter/9}px`,
            height:"fit-content",
            width: "fit-content",
            maxWidth: `${centerDiameter*1.15}px`,
            position:"absolute",
            inset:0,
            margin:"auto",
            transform: `translate(
              ${Math.sin((Math.PI/180)*sectionIncrement*i) * diameter/1.2}px,
              ${-Math.cos((Math.PI/180)*sectionIncrement*i) * diameter/1.45}px
            )`,
            display: "flex",
            gap: `${centerDiameter/12}px`,
            alignItems:"center",
            justifyContent:"space-between"
          }}>
            <span style={{
              fontWeight: "bold",
              fontSize: `${centerDiameter/9}px`,
            }}>
              {label}
            </span>
            <span style={{
              fontWeight: "bold",
              fontSize: `${centerDiameter/6}px`,
              color: colorScale[Math.round(props.labelledMeans[label])]
            }}>
              {props.labelledMeans[label]}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}