import { Button, MenuItem, Select, Stack } from "@mui/material";
import { useContext } from "react";
import { MultiStepContext } from "../StepContext";

const Standardise = () => {
  const { setStep, userData, setUserData } = useContext(MultiStepContext);
  return (
    <form>
      <Stack spacing={2}>
        <label>
          Lighting and heating - Is lighting & heating adequate for the work
          conducted? Any roof leaks?
        </label>
        <Select
          value={userData["question16"]}
          onChange={(e) =>
            setUserData({ ...userData, question16: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Have re-order levels been established, clearly marked and in use?
        </label>
        <Select
          value={userData["question17"]}
          onChange={(e) =>
            setUserData({ ...userData, question17: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Is information vital for work to be carried out available? (SOP's,
          Schedules, Clean & Checksheets, etc.)
        </label>
        <Select
          value={userData["question18"]}
          onChange={(e) =>
            setUserData({ ...userData, question18: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          All Company notice boards up to date and relevant? (not including 5S
          notice board)
        </label>
        <Select
          value={userData["question19"]}
          onChange={(e) =>
            setUserData({ ...userData, question19: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Are there 5S workplace standards - are these up to date and relevant
          for the area?
        </label>
        <Select
          value={userData["question20"]}
          onChange={(e) =>
            setUserData({ ...userData, question20: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setStep(3)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Back
          </Button>

          <Button
            onClick={() => setStep(5)}
            variant="contained"
            color="primary"
            sx={{ width: "4.5rem" }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Standardise;
