import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from "react"
import styles from "./Landing.module.css"
import HeroImg from "../assets/Slide6E2.jpg"
import Nav from "../components/Nav"
import BackToTopButton from "../components/BackToTopButton"
import HeroImg3 from "../assets/Slide3E2.jpg"
import HeroImg2 from "../assets/Slide2E2.jpg"


export default function Landing() {
  const { ref: infopRef, inView: myInfoPIsVisible } = useInView();
  const { ref: knowledgeTransferRef, inView: myKnowledgeTransferIsVisible } = useInView();
  const { ref: heroTextRef, inView: myHeroTestIsVisible } = useInView();
  const { ref: pic1Ref, inView: myPic1IsVisible } = useInView();
  const { ref: pic2Ref, inView: myPic2IsVisible } = useInView();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div className={styles.landing}>
      <Nav/>

    <div className={styles.top}>
      <div className={styles.logoback}>
      <div className={styles.logo_intro}>
        <img src={require("../assets/PiTechLogo2E2.jpg")} alt="PiTech Academy"></img>
      </div>
      </div>
      <div className={styles.infoandpic} idName={styles.infoandpictop}>
      <div className={styles.triangle}></div>
      <div className={styles.info}>
      <h1 style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>What Is PiTech Academy</h1>
      <div className={styles.separator}></div>
      <div>
          <p ref={infopRef} className={`${styles.infop} ${myInfoPIsVisible ? styles.infopactive : ''}`} style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>
            <br></br>
          PiTech Academy is a collaborative ecosystem and network, uniting academia and Industry on a shared 
          platform, leveraging cutting-edge technologies and pioneering research to empower small and 
          medium-sized enterprises (SMEs) in embracing Industry 4.0 (smart manufacturing) and sustainable 
          practices and technologies.
          </p>
        </div>
      </div>
      </div>
      </div>
      <div className={styles.infoandpic}>

      {windowWidth > 800 && (
        <div ref={knowledgeTransferRef} className={`${styles.knowledge_transfer} ${myKnowledgeTransferIsVisible ? styles.knowledge_transferactive : ''}`}>
          <img src={require("../assets/Slide4E2.jpg")} alt="Knowledge Transfer to Industry"></img>
        </div>)}

        {windowWidth <= 800 && (
          <div ref={knowledgeTransferRef} className={`${styles.knowledge_transfer} ${myKnowledgeTransferIsVisible ? styles.knowledge_transferactive : styles.knowledge_transferactive}`}>
          <img src={require("../assets/Slide4E2.jpg")} alt="Knowledge Transfer to Industry"></img>
        </div>
        )}

      </div> 
      

      <img src={require("../assets/Slide1E2.jpg")} alt="Contacts" className={styles.heroImgVision}></img>
      















      
      {windowWidth > 800 && (
        <div>
      <div className={styles.hero} style={{
        backgroundImage:`url(${HeroImg})`, 
        backgroundRepeat: 'no-repeat'
      }}>

        <div ref={heroTextRef} className={`${styles.hero_text} ${myHeroTestIsVisible ? styles.hero_textactive : ''}`}>
          <h1 style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Our Team</h1>
          <p style={{
        fontFamily:"'Titillium Web', sans-serif",
        color:"rgb(61,61,61)"
      }}>
          Experts hailing from Canada's University of Alberta and Mexico's Tecnológico de Monterrey unite their strengths to collaboratively develop technologies, tools, and educational resources aimed at benefiting small and medium-sized enterprises in both nations.
          </p>
        </div>
      </div>

      <div className={styles.progresslanding}>
    
        <div className={styles.info_header}><h1 style={{
        fontFamily:"'Titillium Web', sans-serif",
        marginBottom: 0,
        color: "rgb(0,26,96)"
      }}>Our progress</h1></div>
        <div className={styles.infoBackground}>
        <div className={styles.progressinfo}>
            <p className={styles.progressinfop} style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>
            We specialize in crafting customized training programs designed specifically for small and medium-sized enterprises (SMEs) to effectively implement the latest state-of-the-art tools and technologies.
            </p>
        </div>
        </div>
        <div className={styles.transition}>
        <div className={styles.progresstriangle}></div>
        <div className={styles.progresslogo_intro}>
          <img src={require("../assets/Slide7E2.jpg")} alt="PiTech Academy"></img>
        </div>
        </div>

        <div className={styles.progresshero} style={{
        backgroundImage:`url(${HeroImg3})`, 
        backgroundRepeat: 'no-repeat'
      }}>
        <div className={styles.progresshero_text}>
          <h1 ref={pic1Ref} className={`${styles.pictext1} ${myPic1IsVisible ? styles.pictext1active : ''}`} style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Two days Industrial workshop at Technologico de Monterrey</h1>
        </div>
      </div>
      <div className={styles.progresshero} style={{
        backgroundImage:`url(${HeroImg2})`, 
        backgroundRepeat: 'no-repeat'
      }}>
        <div className={styles.progresshero_text}>
          <h1 ref={pic2Ref} className={`${styles.pictext2} ${myPic2IsVisible ? styles.pictext2active : ''}`} style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Two days Industrial workshop at the University of Alberta</h1>
        </div>
      </div>
    </div>

      </div>
      )}







      {windowWidth <= 800 && (
        <div>
          <h1 style={{
        marginTop: "10vw",
        fontFamily:"'Titillium Web', sans-serif",
        fontSize: "5vw",
        textAlign: "center",
        textDecoration: "underline"
      }}>Our Team</h1>
      <div className={styles.TeamDiv}><img src={require("../assets/Slide6E2.jpg")} className={styles.Team} alt="Team"></img> </div>
     
          
          <p style={{
        fontFamily:"'Titillium Web', sans-serif",
        color:"rgb(61,61,61)",
        fontSize: "3vw",
        lineHeight: "4.2vw"

      }} className={styles.TeamText}>
          Experts hailing from Canada's University of Alberta and Mexico's Tecnológico de Monterrey unite their strengths to collaboratively develop technologies, tools, and educational resources aimed at benefiting small and medium-sized enterprises in both nations.
          </p>












          <div className={styles.progresslanding} style={{
            marginTop: "3rem"
          }}>
    
        <div className={styles.info_header}><h1 style={{
        fontFamily:"'Titillium Web', sans-serif",
        marginBottom: 0,
        color: "rgb(0,26,96)"
      }}>Our progress</h1></div>
        <div className={styles.infoBackground}>
        <div className={styles.progressinfo}>
            <p className={styles.progressinfop} style={{
        fontFamily:"'Titillium Web', sans-serif",
        lineHeight:"2rem"
      }}>
            We specialize in crafting customized training programs designed specifically for small and medium-sized enterprises (SMEs) to effectively implement the latest state-of-the-art tools and technologies.
            </p>
        </div>
        </div>
        <div className={styles.transition}>
        <div className={styles.progresstriangle}></div>
        <div className={styles.progresslogo_intro}>
          <img src={require("../assets/Slide7E2.jpg")} alt="PiTech Academy"></img>
        </div>
        </div>
        <div className={styles.TeamDiv}><img src={require("../assets/Slide3E2.jpg")} className={styles.Team} alt="Team" style={{marginTop: "2rem"}}></img> </div>
        <h1 ref={pic1Ref} style={{
        fontFamily:"'Titillium Web', sans-serif",
        textAlign: "center",
        marginTop: "1vw",
        fontSize: "1rem"
      }}>Two days Industrial workshop at Technologico de Monterrey</h1>

      <div className={styles.TeamDiv}><img src={require("../assets/Slide2E2.jpg")} className={styles.Team} alt="Team" style={{marginTop: "0rem"}}></img> </div>
        <h1 ref={pic1Ref} style={{
        fontFamily:"'Titillium Web', sans-serif",
        textAlign: "center",
        marginTop: "0.2vw",
        fontSize: "1rem"
      }}>Two days Industrial workshop at the University of Alberta</h1>

        
    </div>
        </div>

      
      )}



















      <div className={styles.info} style={{
        marginTop: '10rem'
      }}>

          <h1 className={styles.joinp} style={{
        fontFamily:"'Titillium Web', sans-serif"
      }}>Want to join?</h1>
          <p style={{
        fontFamily:"'Titillium Web', sans-serif",
        color:"rgb(85, 82, 78)"
      }}>
          We invite you to engage with us for your upcoming projects, conduct a comprehensive assessment 
          of your company's Industry 4.0 maturity, and empower your employees with cutting-edge technologies and tools.
          </p>
          <p style={{color: "#0a0752"}}>
          <br></br>
          <br></br>
          <br></br>
          Canadian contact: Dr. Rafiq Ahmad <br></br><br></br>
          Mexican contact: Dr. Armando Roman
          <br></br>
          </p>
      </div>

      <div className={styles.contacts}>
        <img src={require("../assets/Slide5E2.jpg")} alt="Contacts"></img>
      </div>
     {/*} <div className={styles.logos}>
        <img src={require("../assets/logos.jpg")} alt="Contacts"></img>
    </div> */}
      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div>
      <BackToTopButton/>
    </div>
  )

}


