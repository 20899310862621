import React from 'react';
import { useEffect, useState } from 'react';
import { CiCircleChevUp } from "react-icons/ci";

function BackToTopButton() {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 700) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (<div>
        {backToTopButton && (
            <button style={{
                position: "fixed",
                bottom: "40px",
                right: "50px",
                height: "50px",
                fontSize: "50px",
                border: "0",
                borderRadius: "50%",
                background: "white",
                padding: "0"
            }}
            onClick={scrollUp}>
                <CiCircleChevUp/>
            </button>
        )}
    </div>);
}

export default BackToTopButton;