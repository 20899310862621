import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from '../firebase_setup/firebase';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Link } from 'react-router-dom';
import styles from "./SignIn.module.css"
// import Logo from "../assets/logo-grey-no-bg.png"
import Decoration from "../assets/factory.jpg"



/*
// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: "/dashboard",
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // Store user data in sessionStorage.
      sessionStorage.setItem('type', 'Maturity');
      //sessionStorage.setItem('uauth', JSON.stringify(authResult));
      //sessionStorage.setItem('displayName', authResult.user.displayName);
      // Redirect to the specified URL after successful sign-in.
      return true;
    }
  }
}; */

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // Store user data in sessionStorage.
      sessionStorage.setItem('type', 'Maturity');
      // sessionStorage.setItem('uauth', JSON.stringify(authResult));
      // sessionStorage.setItem('displayName', authResult.user.displayName);
      
      // Check the value of 'type' in sessionStorage and redirect accordingly.
      const userType = sessionStorage.getItem('freeResourceChosen');
      let redirectTo = "/";

      if (userType === 'MaturityScan') {
        redirectTo = "/dashboard";
      } else if (userType === '5S') {
        sessionStorage.setItem('previousPage', '/signin');
        redirectTo = "/dashboard5S";
      } else if (userType === 'VSM') {
        sessionStorage.setItem('previousPage', '/signin');
        redirectTo = "/dashboardVSM";
      } else if (userType !== 'Maturity') {
        redirectTo = "/";
      }

      // Redirect to the appropriate URL.
      window.location.href = redirectTo;

      // Return false to prevent the default redirect.
      return false;
    }
  }
};

export default function SignInScreen() {
  //sessionStorage.setItem('type', '')
  return (
    <div className={styles.body}>
      <div className={styles.login}>
        <Link to="/" style={{
        marginLeft: '0px',
      }}>
          <img className={styles.logo} src={require("../assets/PiTechLogo2E2.jpg")} alt="logo"></img>
        </Link>
        <h3 styles={{color: 'white'}}>Transforming your <br/> business just got easier.</h3>
        <span className={styles.title}>Please sign in to continue.</span>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
      </div>
      <div className={styles.sidebar}>
        <img className={styles.sidebar_img} src={Decoration} draggable={false} alt=""/>
        <div className={styles.orgs}>
          <div className={styles.logo_container}>
            <img src={require("../assets/smart.png")} alt="SMART LAB Logo"></img>
            <img src={require("../assets/SmartFactory-TEC.jpg")} alt="SMART Factory Logo"></img>
          </div>
          <div className={styles.logo_container}>
            <img src={require("../assets/ualberta.png")} alt="University of Alberta Logo"></img>
            <img src={require("../assets/tec.png")} alt="Tecnológico de Monterrey Logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}