import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from '../firebase_setup/firebase';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Link } from 'react-router-dom';
import styles from "./SignIn.module.css"
// import Logo from "../assets/logo-grey-no-bg.png"
import Decoration from "../assets/factory.jpg"
//import { collection, addDoc } from "firebase/firestore";
import { getFirestore, collection, addDoc } from "firebase/firestore";


//const settype = () => {
//  sessionStorage.setItem('type', 'Member');
//  let data = sessionStorage.getItem('type');
//  console.log(data)
//}
/*
const addTodo = async (e) => {
   
    try {
        const docRef = await addDoc(collection(db, "todos"), {
          todo: "memberr",    
        });
        console.log("Document written with ID: ", docRef.id);
        localStorage.setItem('todo', docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
} */

const firestore = getFirestore();

//NEWEST OPTION
/*const addRoleToFirestore = async (uid) => {
    const userCollectionRef = collection(firestore, "userRole", uid);
    await addDoc(userCollectionRef, { 'role': 'MEMBER' });
  }; */

/*

const addRoleToFirestore = (uid) => {
  const userCollectionRef = collection(firestore, 'userRole', uid, 'roles');
  return addDoc(userCollectionRef, { 'role': 'exampleValue' });
};

  
// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: "/dashboardMembers",
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // Store user data in sessionStorage.
      //const uid = authResult.user.uid;
      sessionStorage.setItem('type', 'Member');
      const uid = authResult.user.uid;
      //NEWST OPTION
      //addRoleToFirestore(uid);
      addRoleToFirestore(uid)
        .then(() => {
          // Role added successfully
          console.log('Role added successfully.');
          sessionStorage.setItem('done', 'true');
        })
        .catch((error) => {
          // Role addition failed
          console.log('Role NOT successfully.', error);
        });

      //firestore.collection("userRole").doc(uid).set({
      //  role: 'member'
      //})
      // Create a user-specific collection in Firestore
      //const userCollectionRef = collection(firestore, "userRole", uid);
      //await addDoc(userCollectionRef, { 'role': 'exampleValue' })
      //getDocs(collection(firestore, userCollectionRef))
      
      // Add initial data to the user collection if needed
      //addDoc(userCollectionRef, { 'role': 'exampleValue' })
        /*.then(() => {
          // Store user data in sessionStorage.
          sessionStorage.setItem('a', 'mmm');
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          sessionStorage.setItem('b', 'bbb');
        });*/
        
      //setDocument(firestore, authResult);
      
      // Redirect to the specified URL after successful sign-in. */ 
    
     /* return true;
    }
  }
}; */


const addRoleToFirestore = async (uid) => {
  const userCollectionRef = collection(firestore, 'userRole', uid, 'roles');
  await addDoc(userCollectionRef, { 'role': 'MEMBER' });
};

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: "/dashboardMembers",
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // Store user data in sessionStorage.
      sessionStorage.setItem('type', 'Member');
      const uid = authResult.user.uid;

      // Add the role to Firestore and redirect after completion
      addRoleToFirestore(uid)
        .then(() => {
          // Role added successfully
          console.log('Role added successfully.');
          sessionStorage.setItem('done', 'true');

          // Redirect to the specified URL after successful sign-in.
          window.location.assign(redirectUrl || "/dashboardMembers");
        })
        .catch((error) => {
          // Handle errors
          console.log('Error:', error);
        });

      // Prevent the default redirect behavior
      return false;
    }
  }
}; 


export default function Test() {
  //sessionStorage.setItem('type', '')
  return (
    <div className={styles.body}>
      <div className={styles.login}>
        <Link to="/">
          <img className={styles.logo} src={require("../assets/Logo_PiTech Academy-02 - Transparent.png")} alt="logo"></img>
        </Link>
        <h2>Sign up<br/> to become a member.</h2>
        <span className={styles.title}>Please use the account intended for membership.</span>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
        
      </div>
      <div className={styles.sidebar}>
        <img className={styles.sidebar_img} src={Decoration} draggable={false} alt=""/>
        <div className={styles.orgs}>
          <div className={styles.logo_container}>
            <img src={require("../assets/smart.png")} alt="SMART LAB Logo"></img>
            <img src={require("../assets/SmartFactory-TEC.jpg")} alt="SMART Factory Logo"></img>
          </div>
          <div className={styles.logo_container}>
            <img src={require("../assets/ualberta.png")} alt="University of Alberta Logo"></img>
            <img src={require("../assets/tec.png")} alt="Tecnológico de Monterrey Logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
}