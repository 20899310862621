import { Link } from "react-router-dom"
import { auth } from "../firebase_setup/firebase"
import { useState, useEffect } from 'react';
import styles from "./Nav.module.css"
import { IoMenu, IoClose } from "react-icons/io5";

let data = sessionStorage.getItem('type');
let freeData = sessionStorage.getItem('freeResourceChosen');


export default function Nav() {
  const [showMenu, setShowMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showLink = auth.currentUser !== null && data === 'Maturity' && freeData === '5S';
  const showLink1 = auth.currentUser !== null && data === 'Maturity' && freeData === 'MaturityScan';

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <div>
      {windowWidth > 800 && (
        <div className={styles.bar}>
      <Link to="/" className={styles.logo}>
        <img src="/logo.svg" alt=""></img>
      </Link>
      <nav> 
        <Link to="/" className={styles.link}>Home</Link>

        <Link to="/Partners" className={styles.link}>Partners</Link>
        <Link to="/Forum" className={styles.link}>Forum</Link>
        <Link to="/dashboard" className={`${styles.link} ${!showLink1 ? styles.noPadding : ''}`}>{auth.currentUser !== null && data === 'Maturity' && freeData === 'MaturityScan' ? "Dashboard" : null}</Link>
        <Link to="/dashboard5S" className={`${styles.link} ${!showLink ? styles.noPadding : ''}`}>{auth.currentUser !== null && data === 'Maturity' && freeData === '5S' ? "Dashboard" : null}</Link>
        
        <Link to="/freeResources" onClick={() => auth.signOut()} className={styles.link}>
          {/*{auth.currentUser !== null && data === 'Maturity' && freeData === 'MaturityScan' ? "Maturity Scan Logout" : "Maturity Scan"} */}
          {auth.currentUser !== null && data === 'Maturity' ? (freeData === 'MaturityScan' ? "Maturity Scan Logout" : freeData === '5S' ? "5S Logout" : "Maturity Scan") : "Maturity Scan"}
        </Link>
        <Link to="/signup" className={styles.link}>
          {auth.currentUser !== null && data === 'Member'? null : "Members Login"}
        </Link>
        <Link to="/dashboardMembers" className={styles.link}>{auth.currentUser !== null && data === 'Member' ? "Dashboard" : null}</Link>
        <Link to="/" onClick={() => auth.signOut()} className={styles.link}>
          {auth.currentUser !== null && data === 'Member'? "Members Logout" : null}
        </Link>
      </nav>
      </div>
      )}
      {windowWidth <= 800 && (
        <div className={styles.bar1}>
        <div className={styles.bar2}>
          <Link to="/" className={styles.logo}>
        <img src="/logo.svg" alt=""></img>
      </Link>
        <div className={styles.mobileMenuIcon} onClick={toggleMenu}>
          {showMenu ? <IoClose className={styles.menuIcon} /> : <IoMenu className={styles.menuIcon} />}
        </div>
        </div>

        {showMenu ? 
          <div className={styles.navH}>
          <nav> 
        <Link to="/" className={styles.link1}>Home</Link>

        <Link to="/Partners" className={styles.link1}>Partners</Link>
        <Link to="/Forum" className={styles.link1}>Forum</Link>
        {auth.currentUser !== null && data === 'Maturity' && (
        <Link to="/dashboard" className={styles.link1}>Dashboard</Link> )}
        <Link to="/freeResources" onClick={() => auth.signOut()} className={styles.link1}>
          {auth.currentUser !== null && data === 'Maturity'? "Maturity Scan Logout" : "Maturity Scan"}
        </Link>
        {(auth.currentUser === null || data !== 'Member') && (
        <Link to="/signup" className={styles.link1}>Members Login</Link>)}
        {auth.currentUser !== null && data === 'Member' && (
        <Link to="/dashboardMembers" className={styles.link1}>Dashboard</Link> )}
        {auth.currentUser !== null && data === 'Member' && (
        <Link to="/" onClick={() => auth.signOut()} className={styles.link1}>Members Logout</Link>)}
      </nav>
          </div>
          : null }
        </div>
      )}
    </div>
  )
}
