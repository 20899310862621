import React, { useRef } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import styles from './FreeResourcesHub.module.css';
import Nav from '../components/Nav';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { useState } from 'react';



export default function FreeResourcesHub() {
  const flippyRef = useRef(null); // Create a ref using useRef
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleFirstButtonClick = () => {
    sessionStorage.setItem('freeResourceChosen', 'MaturityScan');
  };

  const handleSecondButtonClick = () => {
    sessionStorage.setItem('freeResourceChosen', '5S');
  };
  const handleThirdButtonClick = () => {
    sessionStorage.setItem('freeResourceChosen', 'VSM');
  };

  return (
    <div className={styles.landing}>
    {windowWidth > 1000 && (
      <>
      <Nav />

      <div className={styles.bodycontent}>
        <h2>Choose one to get started</h2>
      <div className={styles.cardBoxBig}>
      <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '30%', height: '65vh', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '1rem' }}>Maturity Scan</h2>
          <img src={require("../assets/MaturityScanImage.png")} alt="Maturity Scan"></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleFirstButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '30%', height: '65vh', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '1rem' }}>5S Audit Checklist</h2>
          <img src={require("../assets/5sAudit2.jpg")} alt="5S Audit Checklist" style={{width: "85%", alignSelf: "center"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleSecondButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '30%', height: '65vh', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '1rem' }}>Automatic Value Stream Mapping</h2>
          <img src={require("../assets/Benefits_VSM.jpg")} alt="5S Audit Checklist" style={{width: "100%", alignSelf: "center"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleThirdButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>
        </div>
      </div>

      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div> 
      </>
      )}

{windowWidth <= 430 && (
      <>
      <Nav />

      <div className={styles.bodycontent}>
        <h2>Choose one to get started</h2>
      <div className={styles.cardBoxSmall}>
      <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '24rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px' }}>Maturity Scan</h2>
          <img src={require("../assets/MaturityScanImage.png")} alt="Maturity Scan"></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleFirstButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '24rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px' }}>5S Audit Checklist</h2>
          <img src={require("../assets/5sAudit2.jpg")} alt="5S Audit Checklist" style={{width: "80%", alignSelf: "center"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleSecondButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '24rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px' }}>Automatic Value Stream Mapping</h2>
          <img src={require("../assets/Benefits_VSM.jpg")} alt="5S Audit Checklist" style={{width: "100%", alignSelf: "center"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleThirdButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>
        </div>
      </div>

      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div> 
      </>
      )}

{windowWidth <= 760 && windowWidth > 430 && (
      <>
      <Nav />

      <div className={styles.bodycontent}>
        <h2>Choose one to get started</h2>
      <div className={styles.cardBoxSmall}>
      <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '82vw', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: '0.3rem' }}>Maturity Scan</h2>
          <img src={require("../assets/MaturityScanImage.png")} alt="Maturity Scan" style={{ maxWidth: "80vw", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleFirstButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem',  }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '75vw', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: '0.3rem' }}>5S Audit Checklist</h2>
          <img src={require("../assets/5sAudit2.jpg")} alt="5S Audit Checklist" style={{maxWidth: "50vw", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleSecondButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '78vw', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: "0.3rem" }}>Automatic Value Stream Mapping</h2>
          <img src={require("../assets/Benefits_VSM.jpg")} alt="5S Audit Checklist" style={{maxWidth: "80vw", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleThirdButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>
        </div>
      </div>

      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div> 
      </>
      )}

{windowWidth <= 1000 && windowWidth > 760 && (
      <>
      <Nav />

      <div className={styles.bodycontent}>
        <h2>Choose one to get started</h2>
      <div className={styles.cardBoxSmall}>
      <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '36rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: '0.3rem' }}>Maturity Scan</h2>
          <img src={require("../assets/MaturityScanImage.png")} alt="Maturity Scan" style={{ maxWidth: "40rem", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleFirstButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '39rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: '0.3rem' }}>5S Audit Checklist</h2>
          <img src={require("../assets/5sAudit2.jpg")} alt="5S Audit Checklist" style={{maxWidth: "32rem", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleSecondButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>

    <Flippy
      flipOnHover={false}
      flipOnClick={false}
      flipDirection="horizontal"
      ref={flippyRef}
      style={{ width: '95%', height: '32rem', display: 'flex', flexDirection: 'column', justifyContent: 'end', marginBottom: '1rem' }}
    >
      <FrontSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        <div className={styles.cardContent} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <h2 style={{ marginTop: '0.3rem', fontSize: '20px', marginBottom: "0.3rem" }}>Automatic Value Stream Mapping</h2>
          <img src={require("../assets/Benefits_VSM.jpg")} alt="5S Audit Checklist" style={{maxWidth: "44rem", height: "auto", alignSelf: "center", marginBottom: "0.5rem"}}></img>
          <Link to="/login" style={{ textDecoration: 'none', margin: 0 }} onClick={handleThirdButtonClick}>
          <Button variant="contained" style={{ backgroundColor: 'rgb(58,105,196)', alignSelf: 'center', marginBottom: '1rem' }} endIcon={<ArrowForwardIosIcon />}>
            Get Started
          </Button>
          </Link>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: 'rgb(229,235,240)' }}>
        ROCKS
      </BackSide>
    </Flippy>
        </div>
      </div>

      <div className={styles.footer}>SMART LAB © 2023. All rights reserved.</div> 
      </>
      )}
    </div>
  );
}
