import { Button, MenuItem, Select, Stack } from "@mui/material";
import { MultiStepContext } from "../StepContext";
import { useContext } from "react";

const Sustain = () => {
  // Access context for step control, user data, and submit function
  const { setStep, userData, setUserData, submitData } =
    useContext(MultiStepContext);
  return (
    <form>
      <Stack spacing={2}>
        <label>
          Are checklists available and used, are audit results displayed? (no
          limited to 5S )
        </label>
        <Select
          value={userData["question21"]}
          onChange={(e) =>
            setUserData({ ...userData, question21: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          5S Board - Up to date, regularly reviewed? (if no board exists = 0)
        </label>
        <Select
          value={userData["question22"]}
          onChange={(e) =>
            setUserData({ ...userData, question22: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Have the 5S improvement actions suggestions been actioned?
        </label>
        <Select
          value={userData["question23"]}
          onChange={(e) =>
            setUserData({ ...userData, question23: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Has the standard in the area been properly policed &
          maintained/improved?
        </label>
        <Select
          value={userData["question24"]}
          onChange={(e) =>
            setUserData({ ...userData, question24: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <label>
          Education of employees - Can the people in the area explain the
          benefits of 5S?
        </label>
        <Select
          value={userData["question25"]}
          onChange={(e) =>
            setUserData({ ...userData, question25: e.target.value })
          }
        >
          <MenuItem disabled value={-1}>
            <Stack sx={{ color: "gray" }}>
              <em>Select a value ...</em>
            </Stack>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>

        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => setStep(4)}
            variant="contained"
            color="primary"
          >
            Back
          </Button>

          <Button onClick={submitData} variant="contained" color="success">
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Sustain;
